import { Component } from '@angular/core'
import { FieldWrapper } from '@ngx-formly/core'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'

@Component({
	selector: 'kk-tooltip-wrapper',
	standalone: true,
	imports: [NgbTooltipModule],
	template: `
		<span placement="top" container="body" [ngbTooltip]="props['tooltip']">
			<ng-container #fieldComponent></ng-container>
		</span>
	`,
})
export class TooltipWrapperComponent extends FieldWrapper {}
