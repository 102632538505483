import { Environment } from '../../ngrx-store/models'

const envKey = 'environment'
const tokenKey = 'licenseToken'
const versionInfoKey = 'versionInfo'

export function saveEnvToLocalStorage(currentEnvironment: Environment) {
	localStorage.setItem(envKey, JSON.stringify(currentEnvironment))
}

export function saveLicenseTokenToLocalStorage(licenseToken: string) {
	localStorage.setItem(tokenKey, licenseToken)
}

export function saveVersionInfoToLocalStorage(versionInfo: string) {
	localStorage.setItem(versionInfoKey, versionInfo)
}

export function getEnvFromLocalStorage(): Environment | null {
	const json = localStorage.getItem(envKey)
	const env: Environment | null = json ? JSON.parse(json) : null
	return env
}

export function getLicenseTokenFromLocalStorage(): string {
	return localStorage.getItem(tokenKey) ?? ''
}

export function getVersionInfoFromLocalStorage(): string {
	return localStorage.getItem(versionInfoKey) ?? ''
}
