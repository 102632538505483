import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { MbscSelectModule } from '@mobiscroll/angular'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { Dimension, DimensionLevel, DimensionSelectionType } from 'src/app/core/ngrx-store/models'
import {
	DimensionLevelService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { filter, forkJoin, Subscription, take } from 'rxjs'
import { DimensionDataService } from 'src/app/core/ngrx-store/dimension/dimension.data-service'
import {
	DimensionDisplayMode,
	WorktimeGroup,
} from 'src/app/core/ngrx-store/worktime-group/worktime-group.model'
import { SortUtils } from 'src/app/core/utils/sort-utils'
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component'

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.Default,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		CommonModule,
		MbscSelectModule,
		MatSelectModule,
		MatAutocompleteModule,
		LoadingComponent,
	],
	selector: 'kk-dimension-select',
	templateUrl: './dimension-select.component.html',
	styleUrls: ['./dimension-select.component.scss'],
})
export class DimensionSelectComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit, OnDestroy
{
	constructor(
		public readonly dimensionLevelService: DimensionLevelService,
		private readonly dimensionDataService: DimensionDataService,
		private readonly worktimeGroupService: WorktimeGroupService,
		private readonly cdr: ChangeDetectorRef
	) {
		super()
	}

	placeholder = $localize`Valitse`
	placeholderSearch = $localize`Hae kohteen nimellä`
	showComponent = false
	worktimeGroupId: number
	dimensions: Dimension[]
	dimensionLevels: DimensionLevel[]
	worktimeGroup: WorktimeGroup | undefined
	selectedDimensions: number[] = []
	filteredDimensions: Dimension[]
	autoCompleteFormControl = new FormControl()
	formGroup: FormGroup
	loading = signal(true)
	private readonly subscriptions: Subscription = new Subscription()
	eventSource: DimensionSelectionType | undefined

	ngOnInit(): void {
		this.worktimeGroupId = this.props['worktimeGroupId']
		this.setModel()
		this.initializeFormGroup()
		this.eventSource = this.dimensionDataService.getSource()
		this.loadDimensions()
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe()
	}

	/*
	 * Sets the model
	 */
	setModel(): void {
		if (this.model.selectedDimensions) {
			this.selectedDimensions = this.model.selectedDimensions
		}
	}

	/*
	 * Loads the dimensions and dimension levels
	 */
	loadDimensions(): void {
		const subscription = forkJoin({
			dimensions: this.dimensionDataService.getAll(),
			dimensionLevels: this.dimensionLevelService.entities$.pipe(
				filter((entities) => entities.length > 0),
				take(1)
			),
			worktimeGroups: this.worktimeGroupService.entities$.pipe(
				filter((entities) => entities.length > 0),
				take(1)
			),
		}).subscribe({
			next: ({ dimensions, dimensionLevels, worktimeGroups }) => {
				// Filter out the worktime group by id
				this.worktimeGroup = worktimeGroups.find(
					(worktimeGroup) => worktimeGroup.id === this.worktimeGroupId
				)

				// Filter out the dimensions by active status
				this.dimensions = dimensions.filter((dimension) => dimension.active)

				// Filter out the dimension levels depending on if selection is on stamp 
				// or event then filter out the levels by active status, sort by order number
				// and set hidden status based on worktime group dimension levels
				if (this.eventSource === DimensionSelectionType.Stamp) {
					this.dimensionLevels = dimensionLevels
						.filter((dimensionLevel) => dimensionLevel.disabled === false)
						.map((level) => ({
							...level,
							hidden: !this.worktimeGroup?.dimensionLevelsForStamp.includes(level.id),
						}))
						.sort((a, b) => a.orderNumber - b.orderNumber)
				} else {
					this.dimensionLevels = dimensionLevels
						.filter((dimensionLevel) => dimensionLevel.disabled === false)
						.map((level) => ({
							...level,
							hidden: !this.worktimeGroup?.dimensionLevelsForEvent.includes(level.id),
						}))
						.sort((a, b) => a.orderNumber - b.orderNumber)
				}

				// Show the component if there are visible dimension levels
				if (this.dimensionLevels.filter((level) => !level.hidden).length > 0) {
					this.showComponent = true
				}

				this.loading.set(false)

				this.filteredDimensions = this.dimensions
				this.setupFilteredDimensions()
				this.cdr.detectChanges() // change detection was not triggered in edit-event-modal
			},
		})

		this.subscriptions.add(subscription)
	}

	/*
	 * Filters the dimensions by parent id
	 */
	getDimensionsFilteredByParentId(dimensionLevelId: number): Dimension[] {
		const dimensions = this.dimensions.filter(
			(dimension) =>
				dimension.dimensionLevelId === dimensionLevelId &&
				this.isParentDimensionSelected(dimension.parentDimensionId)
		)
		if (dimensions.length === 0) {
			this.clearSelectionAndForm(dimensionLevelId)
		}

		return SortUtils.naturalSort(dimensions, 'dimensionId')
	}

	/*
	 * Selects a dimension and parents
	 */
	setSelection(
		dimension: Dimension | undefined,
		dimensionLevelId: number | undefined
	): void {
		if (dimensionLevelId === undefined) {
			dimensionLevelId = dimension?.dimensionLevelId
		}
		this.setFormValue(dimensionLevelId, dimension)
		this.updateSelection(dimension, dimensionLevelId)

		if (this.isParentLevelHidden(dimensionLevelId) && !dimension) {
			const parentDimensionLevelId = this.dimensionLevels.find(
				(level) => level.id === dimensionLevelId
			)?.parentId
			if (parentDimensionLevelId) {
				this.setSelection(undefined, parentDimensionLevelId)
			}
		}

		if (dimension?.parentDimensionId) {
			const parentDimensionId = dimension?.parentDimensionId
			const parentDimension = this.dimensions.find(
				(dimension) => dimension.id === parentDimensionId
			)
			if (parentDimension) {
				this.setSelection(parentDimension, parentDimension.dimensionLevelId)
			}
		}
	}

	/*
	 * Check if dimension level is hidden
	 */
	isParentLevelHidden(dimensionLevelId: number | undefined): boolean {
		if (!dimensionLevelId) {
			return false
		}

		let parentLevelId = this.dimensionLevels.find(
			(level) => level.id === dimensionLevelId
		)?.parentId

		return !!this.dimensionLevels.find((level) => level.id === parentLevelId)
			?.hidden
	}

	/*
	 * Updates the model entries
	 */
	updateSelection(
		dimension: Dimension | undefined,
		dimensionLevelId: number | undefined
	): void {
		if (!dimensionLevelId) {
			return
		}
		if (!dimension) {
			this.clearSelectionAndForm(dimensionLevelId)
			this.clearChildDimensionLevels(dimensionLevelId)
			return
		}

		const dimensionsWithSameLevel = this.dimensions.filter(
			(dimension) => dimension.dimensionLevelId === dimensionLevelId
		)

		this.selectedDimensions = this.selectedDimensions.filter(
			(selectedDimension) =>
				!dimensionsWithSameLevel.some(
					(dimension) => dimension.id === selectedDimension
				)
		)

		if (this.selectedDimensions.includes(dimension.id)) {
			return
		} else {
			this.selectedDimensions = [...this.selectedDimensions, dimension.id]
		}

		this.updateUnsavedEdits()
	}

	/*
	 * Clears child dimensionLevels in model and form
	 */
	clearChildDimensionLevels(dimensionLevelId: number | undefined): void {
		if (!dimensionLevelId) {
			return
		}

		this.clearSelectionAndForm(dimensionLevelId)

		const childDimensionLevels = this.dimensionLevels.filter(
			(dimensionLevel: DimensionLevel) =>
				dimensionLevel.parentId === dimensionLevelId
		)

		childDimensionLevels.forEach((childDimensionLevel) => {
			this.clearChildDimensionLevels(childDimensionLevel.id)
		})

		this.updateUnsavedEdits()
	}

	/*
	 * Check if parent control is selected
	 */
	isParentLevelSelected(parentLevelId: number | undefined): boolean {
		if (!parentLevelId) {
			return true
		}
		const parentLevelIsHidden = this.dimensionLevels.find(
			(level) => level.id === parentLevelId
		)?.hidden

		const parentControl = this.formGroup.get(parentLevelId.toString())
		return parentControl
			? !!parentControl.value || !!parentLevelIsHidden
			: false
	}

	/*
	 * Check if parent control is selected
	 */
	isParentDimensionSelected(parentDimensionId: number | undefined): boolean {
		if (!parentDimensionId) {
			return true
		}
		const parentDimensionLevelId = this.dimensions.find(
			(dimension) => dimension.id === parentDimensionId
		)?.dimensionLevelId

		const parentDimensionLevelIsHidden =
			this.isParentDimensionLevelHidden(parentDimensionId)

		if (!parentDimensionLevelId) {
			return true
		}
		const parentControl = this.getForm(parentDimensionLevelId)

		return parentControl
			? parentControl.value?.id === parentDimensionId ||
					!!parentDimensionLevelIsHidden
			: false
	}

	/*
	 * Check if parent dimension level is hidden
	 */
	isParentDimensionLevelHidden(parentDimensionId: number | undefined): boolean {
		if (!parentDimensionId) {
			return false
		}
		const parentDimensionLevelId = this.dimensions.find(
			(dimension) => dimension.id === parentDimensionId
		)?.dimensionLevelId

		const parentDimensionLevel = this.dimensionLevels.find(
			(level) => level.id === parentDimensionLevelId
		)
		return !!parentDimensionLevel?.hidden
	}

	// Get dimension name with display mode
	getDimensionName(dimension: Dimension): string {
		const dimensionDisplayMode = this.worktimeGroup?.dimensionDisplayMode
		if (dimensionDisplayMode) {
			switch (dimensionDisplayMode) {
				case DimensionDisplayMode.ShowAndOrderById:
					return `${dimension.dimensionId} ${dimension.name}`
				default:
					return dimension.name
			}
		} else {
			return dimension.name
		}
	}

	// Autocomplete methods

	/*
	 * Sets up the filtered dimensions
	 */
	setupFilteredDimensions(): void {
		this.autoCompleteFormControl.valueChanges.subscribe((value) => {
			this.filteredDimensions = this.filterDimensions(value)
		})
	}

	/*
	 * Displays the dimension name
	 */
	displayFn(selectedoption: Dimension): string {
		return selectedoption ? selectedoption.name : ''
	}

	/*
	 * Filters the dimensions
	 */
	filterDimensions(value: string | Dimension): Dimension[] {
		if (!value) {
			return this.dimensions
		}
		const filterValue =
			typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase()
		return this.dimensions.filter(
			(option) =>
				option.name.toLowerCase().includes(filterValue) ||
				option.dimensionId.toLowerCase().includes(filterValue)
		)
	}

	// Get dimension full path to autocomplete selection
	getDimensionFullPath(id: number): string {
		const dimension = this.dimensions.find((dimension) => dimension.id === id)
		if (!dimension) {
			return ''
		}

		const parentDimensionPath = dimension.parentDimensionId
			? this.getDimensionFullPath(dimension.parentDimensionId) + ' / '
			: ''

		return `${parentDimensionPath}${dimension.dimensionId} ${dimension.name}`
	}

	// Form control and model control methods

	/*
	 * Initializes the form group
	 */
	initializeFormGroup(): void {
		this.formGroup = new FormGroup({})
	}

	/*
	 * Creates a form control
	 */
	createFormControl(id: number): FormControl {
		const control = new FormControl()
		this.formGroup.addControl(id.toString(), control)
		return control
	}

	/*
	 * Gets a form control
	 */
	getForm(id: number): FormControl {
		const form = this.formGroup.get(id.toString()) as FormControl
		this.setSelections(id, form)
		return form
	}

	/*
	 * Clears a form control
	 */
	clearForm(id: number): void {
		const formControl = this.formGroup.get(id.toString()) as FormControl
		formControl.reset()
	}

	/*
	 * Sets a form control
	 */
	setFormValue(id: number | undefined, dimension: Dimension | undefined): void {
		if (!id) {
			return
		}
		const formControl = this.formGroup.get(id.toString()) as FormControl
		formControl.setValue(dimension)
	}

	/*
	 * Sets the selections
	 */
	setSelections(dimensionLevelId: number, form: FormControl): void {
		this.selectedDimensions?.forEach((dimensionId) => {
			const dimension = this.dimensions.find(
				(dimension) =>
					dimension.id === dimensionId &&
					dimension.dimensionLevelId === dimensionLevelId
			)
			if (dimension && form) {
				form.setValue(dimension)
			}
		})
	}

	/*
	 * Clears selection and form
	 */
	clearSelectionAndForm(dimensionLevelId: number): void {
		this.clearForm(dimensionLevelId)
		this.removeDimensionByLevelId(dimensionLevelId)
		this.updateUnsavedEdits()
	}

	/*
	 * Removes a dimension by level id
	 */
	removeDimensionByLevelId(dimensionLevelId: number): void {
		const levelDimensions = this.dimensions.filter(
			(dimension) => dimension.dimensionLevelId === dimensionLevelId
		)

		this.selectedDimensions = this.selectedDimensions?.filter(
			(selectedDimension) =>
				!levelDimensions.some((dimension) => dimension.id === selectedDimension)
		)
	}

	/*
	 * Updates the unsaved edits
	 */
	updateUnsavedEdits() {
		this.model.selectedDimensions = this.selectedDimensions
	}
}
