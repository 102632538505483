import { Component } from '@angular/core'
import { CommonModule, NgIf } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { SessionService } from './core/services/session/session.service'
import { EnvironmentSelectComponent } from './features/environment-select/environment-select.component'
import {
	AuthService,
	AuthenticationStatus,
} from './core/services/auth/auth.service'
import { LetDirective } from '@ngrx/component'
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap'
import { LoadingDirective } from './shared/directives/loading/loading.directive'
import { LoadingComponent } from './shared/components/loading/loading.component'
import { LayoutComponent } from './core/components/layout/layout.component'
import { combineLatest, map, Observable, of, switchMap } from 'rxjs'
import { UserService } from './core/ngrx-store/entity-services'
import { ToastListComponent } from './core/components/toast/toast-list.component'
import { User } from '@sentry/angular'
import { toObservable } from '@angular/core/rxjs-interop'

@Component({
	selector: 'kk-root',
	templateUrl: './app.component.html',
	standalone: true,
	imports: [
		CommonModule,
		RouterOutlet,
		EnvironmentSelectComponent,
		NgIf,
		LetDirective,
		ToastListComponent,
		LoadingDirective,
		LoadingComponent,
		LayoutComponent,
	],
})
export class AppComponent {
	environment = this.sessionService.environment
	authenticated$ = this.authService.authStatus$.pipe(
		map((status) => status === AuthenticationStatus.Authenticated)
	)
	currentUser$: Observable<User | null> = combineLatest([
		this.authenticated$,
		toObservable(this.environment),
	]).pipe(
		switchMap(([isAuthenticated, environment]) => {
			if (isAuthenticated && environment) {
				return this.userService.getCurrentUser()
			} else {
				return of(null)
			}
		})
	)

	constructor(
		private readonly sessionService: SessionService,
		private userService: UserService,
		private authService: AuthService,
		private ngbConfig: NgbModalConfig
	) {
		//  NgbModalConfig needs to be defined in some root level component.
		this.ngbConfig.ariaLabelledBy = 'modal-basic-title'
		this.ngbConfig.animation = true
		this.ngbConfig.centered = true
		this.ngbConfig.scrollable = true
	}
}
