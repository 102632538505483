import { ChangeDetectionStrategy, Component } from '@angular/core'
import {
	ModalBodyComponent,
	ModalComponent,
} from 'src/app/shared/components/modal/modal.component'
import { DateTimePickerComponent } from 'src/app/shared/components/form/types/datetime-picker/datetime-picker.component'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core'
import { emptyRow } from 'src/app/shared/components/form/formly-field'
import { WorktimeGroupHistory } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history.model'
import {
	WorktimeGroupService,
	WorktimeGroupHistoryService,
	PersonService,
} from 'src/app/core/ngrx-store/entity-services'
import { map } from 'rxjs'
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap'
import { selectWorktimeGroupHistoryForPerson } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history.selectors'
import { Store } from '@ngrx/store'

@Component({
	selector: 'kk-worktime-group-history-modal',
	imports: [
		ModalComponent,
		ModalBodyComponent,
		DateTimePickerComponent,
		FormlyModule,
		ReactiveFormsModule,
		FormlyBootstrapModule,
	],
	providers: [WorktimeGroupService, WorktimeGroupHistoryService],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<kk-modal title="Työaikaryhmähistoria" i18n-title>
			<kk-modal-body>
				<form [formGroup]="form" data-testid="ManagementCreateNewForm">
					<formly-form [form]="form" [fields]="fields" [model]="model" />
				</form>
			</kk-modal-body>
		</kk-modal>
	`,
})
export class WorktimeGroupHistoryComponent {
	worktimeGroupHistory$ = this.store.select(selectWorktimeGroupHistoryForPerson)

	constructor(
		private worktimeGroupService: WorktimeGroupService,
		private worktimeGroupHistoryService: WorktimeGroupHistoryService,
		private personService: PersonService,
		private store: Store
	) {}

	onSubmit() {
		if (
			this.model.groupId !== undefined &&
			this.model.startTime !== undefined &&
			this.model.personId !== undefined
		) {
			this.worktimeGroupHistoryService
				.add({ ...this.model }, { isOptimistic: false })
				.subscribe(() => {
					this.personService.getByKey(this.model.personId)
				})
		}
	}

	model: Partial<WorktimeGroupHistory> = {
		groupId: undefined,
		startTime: undefined,
		personId: undefined,
	}

	form = new FormGroup({})
	fields: FormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'grid gap-0 column-gap-sm-3',
			fieldGroup: [
				{
					key: 'groupId',
					type: 'select',
					className: 'g-col-12',
					props: {
						required: true,
						label: $localize`Työaikaryhmä`,
						options: this.worktimeGroupService.entities$.pipe(
							map((worktimeGroups) =>
								worktimeGroups.map((group) => ({
									value: group.id,
									label: group.name,
								}))
							)
						),
					},
				},
				{
					key: 'startTime',
					type: 'datetime',
					className: 'g-col-12',
					props: {
						required: true,
						label: $localize`Alkupäivä`,
						min: (() => {
							const currentDate = new Date()
							const nextDay = new Date(currentDate)
							nextDay.setDate(currentDate.getDate() + 1)
							return nextDay.getTime()
						})(),
						controls: ['date'],
						dateFormat: 'D.M.YYYY',
						returnFormat: 'iso8601',
					},
				},
				{
					type: 'button',
					className: 'g-col-5',
					props: {
						label: 'Lisää',
						onClick: () => this.onSubmit(),
						className: 'btn btn-success',
					},
				},
				emptyRow,
				{
					type: 'worktime-group-history-table',
					className: 'g-col-12',
					props: {
						items: this.worktimeGroupHistory$,
					},
				},
				emptyRow,
			],
			hooks: {
				onInit: () => {
					if (this.model.personId !== undefined) {
						this.worktimeGroupHistoryService.loadWithQuery({
							personId: this.model.personId,
						})
					}
				},
			},
		},
	]
}
