import { Component } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { WorktimeGroupHistoryComponent } from 'src/app/features/management/components/worktime-group-history-modal/worktime-group-history-modal.component'

@Component({
	imports: [FormlyModule],
	standalone: true,
	selector: 'kk-worktime-group-history-button',
	template: `
		<div>
			<br />
			<button
				(click)="onClick()"
				type="button"
				class="btn btn-light me-2 d-flex justify-content-center align-items-center"
				data-testid="worktime-group-history-button"
			>
				<i class="fa-light fa-user-group pe-2"></i>
				<p i18n class="text-nowrap">{{ props.label }}</p>
			</button>
		</div>
	`,
})
export class WorktimeGroupChangeButtonComponent extends FieldType<FieldTypeConfig> {
	constructor(private modalService: NgbModal) {
		super()
	}

	onClick() {
		const modalRef = this.modalService.open(WorktimeGroupHistoryComponent)
		const component =
			modalRef.componentInstance as WorktimeGroupHistoryComponent
		component.model.personId = this.formControl.getRawValue()
	}
}
