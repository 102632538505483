import { Component, ChangeDetectionStrategy } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import {
	PersonService,
	PersonTemplateService,
} from 'src/app/core/ngrx-store/person/person.service'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { textField } from 'src/app/shared/components/form/formly-field'
import { EntityForm } from '../../models'
import { map } from 'rxjs'
import { HttpOptions } from '@ngrx/data/src/dataservices/interfaces'
import { WorktimeGroupService } from 'src/app/core/ngrx-store/entity-services'
import { Router } from '@angular/router'
import { personEntityType } from './person'

@Component({
	standalone: true,
	selector: 'kk-person-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi henkilö"
			i18n-title
		/>
	`,
})
export class PersonCreateNewContentComponent implements EntityForm {
	constructor(
		private readonly personService: PersonService,
		private readonly personTemplateService: PersonTemplateService,
		private readonly worktimeGroupService: WorktimeGroupService,
		private readonly router: Router
	) {
		this.personTemplateService.getAll()
	}

	model: { name: string; worktimeGroupId: number; templateNumber?: number } = {
		name: '',
		worktimeGroupId: 0,
		templateNumber: undefined,
	}

	fields: FormlyFieldConfig[] = [
		textField('name', $localize`Nimi`),
		{
			key: 'worktimeGroupId',
			type: 'select',
			// className: 'g-col-12',
			props: {
				label: $localize`Työaikaryhmä`,
				required: true,
				options: this.worktimeGroupService.entities$.pipe(
					map((worktimeGroups) =>
						worktimeGroups.map((worktimeGroup) => ({
							value: worktimeGroup.id,
							label: worktimeGroup.name,
						}))
					)
				),
			},
			hooks: {
				onInit: (field) => {
					this.worktimeGroupService.entities$.subscribe((worktimeGroups) => {
						if (worktimeGroups.length > 0) {
							field.formControl?.setValue(worktimeGroups[0].id)
						}
					})
				},
			},
		},
		{
			key: 'templateNumber',
			type: 'select',
			props: {
				label: $localize`Mallipohja`,
				options: this.personTemplateService.entities$.pipe(
					map((templates) => [
						{
							value: undefined,
							label: '-',
						},
						...templates.map((template) => ({
							value: template.id,
							label: template.name,
						})),
					])
				),
			},
		},
	]

	onSubmit() {
		const httpOptions: HttpOptions = {}

		if (this.model.templateNumber) {
			httpOptions.httpParams = {
				fromObject: {
					templateNumber: this.model.templateNumber,
				},
			}
		}

		this.personService
			.add(
				{ name: this.model.name, worktimeGroupId: this.model.worktimeGroupId },
				{ isOptimistic: false, httpOptions }
			)
			?.subscribe((entity) => {
				if (entity) {
					this.router.navigate(['management', personEntityType.path, entity.id])
				}
			})
	}
}
