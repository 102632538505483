import { personEntityType } from './person/person'
import { worktimeGroupEntityType } from './worktime-group/worktime-group'
import { eventTypeEntityType } from './event-type/event-type'
import { workShiftEntityType } from './workshift/workshift'
import { dimensionEntityType } from './dimension/dimension'
import { dimensionLevelEntityType } from './dimension-level/dimension-level'
import { stampingReasonEntityType } from './stamping-reason/stamping-reason'
import { balanceLock } from './balance-lock/balance-lock'

/**
 * The entity types available. Use `entityTypeProviderFactory` to provide only the enabled entity types.
 */
export const allEntityTypes = [
	personEntityType,
	worktimeGroupEntityType,
	eventTypeEntityType,
	stampingReasonEntityType,
	workShiftEntityType,
	dimensionEntityType,
	dimensionLevelEntityType,
	balanceLock,
	stampingReasonEntityType,
]
