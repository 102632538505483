import { Injectable } from '@angular/core'
import { PaginationDataService } from '../paginated-data.service'
import { HttpClient } from '@angular/common/http'
import { HttpUrlGenerator } from '@ngrx/data'
import { Observable } from 'rxjs'
import { BalanceLock } from './balance-lock.model'

@Injectable()
export class BalanceLockDataService extends PaginationDataService<BalanceLock> {
	constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator) {
		super('BalanceLock', http, httpUrlGenerator)
	}

	override getAll(): Observable<BalanceLock[]> {
		return this.getAllPages()
	}
}
