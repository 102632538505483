import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { WorktimeWeek } from '../models'
import { Observable, shareReplay } from 'rxjs'

@Injectable()
export class StampHandlerService {
	constructor(private http: HttpClient) {}

	updateBalances(
		worktimeWeek: WorktimeWeek
	): Observable<{ success: boolean; startDate: string | null }> {
		return this.http
			.get<{
				success: boolean
				startDate: string | null
			}>(
				`features/stamphandler/RunStampHandlerForPerson/` +
					worktimeWeek.personId +
					`/` +
					worktimeWeek.startDate
			)
			.pipe(shareReplay())
	}
}
