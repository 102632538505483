export interface Dimension {
	id: number
	dimensionId: string
	name: string
	dimensionLevelId: number
	parentDimensionId?: number
	startTime: string
	endTime: string
	active: boolean
	worktimeGroups: number[]
	parentDimensionOptionalId: string
	parentDimensionName: string
	projectManagers: number[]
}

export enum DimensionSelectionType {
	Stamp = 'Stamp',
	Event = 'Event',
}
