import { FormlyFieldConfig } from '@ngx-formly/core'

/**
 * Idea copied from https://github.com/ngx-formly/ngx-formly/blob/abaf3e92e370c6a184981717dc38d6fdcfbe86eb/src/ui/bootstrap/addons/src/addon.extension.ts
 */
export function tooltipExtension(field: FormlyFieldConfig) {
	if (
		!field.props ||
		(field.wrappers && field.wrappers.indexOf('tooltip') !== -1)
	) {
		return
	}

	if (field.props['tooltip']) {
		field.wrappers = [...(field.wrappers || []), 'tooltip']
	}
}
