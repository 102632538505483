import {
	ChangeDetectionStrategy,
	Component,
	OnChanges,
	SimpleChanges,
} from '@angular/core'
import { EntityForm } from '../../models'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { map } from 'rxjs'
import { CreateBalanceLock } from 'src/app/core/ngrx-store/balance-lock/balance-lock.model'
import {
	BalanceLockService,
	PersonService,
} from 'src/app/core/ngrx-store/entity-services'
import { FormlyUtils } from 'src/app/core/utils/formly-utils'
import { TimeSpan } from 'src/app/core/utils/time-span'

@Component({
	standalone: true,
	selector: 'kk-balance-lock-create',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	styleUrl: './balance-lock-create.component.scss',
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi saldolukitus"
			i18n-title
		/>
	`,
})
export class BalanceLockCreateComponent implements EntityForm, OnChanges {
	model: Partial<CreateFormModel> = {}
	fields: FormlyFieldConfig[] = [
		{
			key: 'personId',
			type: 'mbsc-select',
			props: {
				placeholder: $localize`Valitse henkilö`,
				label: $localize`Henkilö`,
				filter: true,
				required: true,
				data: this.personService
					.getWorktimePersons()
					.pipe(
						map((personsArray) =>
							personsArray.map((i) => ({ text: i.name, value: i.id }))
						)
					),
			},
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					field.formControl?.valueChanges.subscribe(() => {
						this.updateDefaultBalance()
					})
				},
			},
		},
		{
			key: 'date',
			type: 'datetime',
			props: {
				label: $localize`Saldon pvm.`,
				controls: ['date'],
				dateFormat: 'D.M.YYYY',
				returnFormat: 'iso8601',
				required: true,
			},
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					field.formControl?.valueChanges.subscribe(() => {
						this.updateDefaultBalance()
					})
				},
			},
		},
		{
			key: 'balance',
			type: 'duration-picker',
			props: {
				label: $localize`Uusi liukumasaldo`,
				required: true,
			},
			hooks: {
				onChanges: (field: FormlyFieldConfig) => {
					if (field.formControl?.value) this.durationManuallySet = true
				},
			},
		},
	]

	durationField = FormlyUtils.getFieldByKey('balance', this.fields)
	personIdField = FormlyUtils.getFieldByKey('personId', this.fields)
	dateField = FormlyUtils.getFieldByKey('date', this.fields)
	private durationManuallySet = false
	constructor(
		private readonly balanceLockService: BalanceLockService,
		private readonly personService: PersonService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		this.durationManuallySet = false
	}

	private updateDefaultBalance() {
		// If user has allready set value don't override it
		if (this.durationManuallySet) {
			return
		}

		const personId = this.personIdField?.formControl?.value
		const date = this.dateField?.formControl?.value

		if (!personId || !date) return

		this.balanceLockService
			.getWorkTimeFlexBalance(personId, date)
			.subscribe((balance) => {
				if (balance) {
					const timeSpan = TimeSpan.fromMinutes(balance)
					this.durationField?.formControl?.setValue(timeSpan.toStringHHmm())
				} else {
					this.durationField?.formControl?.setValue('')
				}
			})
	}

	onSubmit() {
		const timeSpan = TimeSpan.fromStringHHmm(this.model.balance)
		if (!timeSpan) throw new Error('Invalid duration')

		this.balanceLockService.create({
			date: this.model.date as string,
			personId: this.model.personId as number,
			minutes: Math.floor(timeSpan.totalMinutes),
		})
	}
}
class CreateFormModel {
	date: string
	balance: string
	personId: number
}
