import { Component, OnInit } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { MbscSelectModule } from '@mobiscroll/angular'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import {
	StampAutomaticFillSetting,
	StampAutomaticFillType,
	StampAutomaticFillTypeLabels,
	StampDirectionEnum,
	StampingReason,
} from 'src/app/core/ngrx-store/stamping-reason/stamping-reason.model'
import { MatOptionModule } from '@angular/material/core'
import { MatListModule } from '@angular/material/list'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { Observable } from 'rxjs'
import { FormsModule } from '@angular/forms'
import { MatCheckbox } from '@angular/material/checkbox'
import { ManagementFormService } from 'src/app/features/management/management-form.service'
import { EventType } from 'src/app/core/ngrx-store/event-type/event-type.model'

@Component({
	standalone: true,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		CommonModule,
		MbscSelectModule,
		MatSelectModule,
		MatOptionModule,
		MatListModule,
		MatExpansionModule,
		MatInputModule,
		MatFormFieldModule,
		FormsModule,
		MatCheckbox,
	],
	selector: 'kk-stamp-automatic-fill-setting-list',
	template: `
		<mat-selection-list [formlyAttributes]="field" #fillSettings>
			<mat-expansion-panel
				*ngFor="
					let item of this.model.stampAutomaticFillSettings;
					let index = index
				"
			>
				<mat-expansion-panel-header>
					<mat-checkbox
						#CheckBox
						[(ngModel)]="this.model.stampAutomaticFillSettings[index].enabled"
						(click)="
							handleConflictingCheckBoxes(index); $event.stopPropagation()
						"
						(change)="updateUnsavedEdits()"
					>
						<label>
							{{ getAutomaticFillTypeLabel(item.stampAutomaticFillType) }}
						</label>
					</mat-checkbox>
				</mat-expansion-panel-header>

				<label class="form-label">{{ eventTypeLabel }}</label>
				<mat-form-field appearance="outline">
					<mat-select
						[(ngModel)]="
							this.model.stampAutomaticFillSettings[index].eventTypeId
						"
						[disabled]="!this.model.stampAutomaticFillSettings[index].enabled"
						(selectionChange)="updateUnsavedEdits()"
					>
						<mat-option
							*ngFor="let eventType of this.eventTypes | async"
							[value]="eventType.id"
						>
							{{ eventType.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<label class="form-label">{{ stampInReasonLabel }}</label>
				<mat-form-field appearance="outline">
					<mat-select
						[(ngModel)]="
							this.model.stampAutomaticFillSettings[index].stampInReasonId
						"
						[disabled]="!this.model.stampAutomaticFillSettings[index].enabled"
						(selectionChange)="updateUnsavedEdits()"
					>
						<mat-option
							*ngFor="let stampInReason of this.stampInReasons | async"
							[value]="stampInReason.id"
						>
							{{ stampInReason.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<label class="form-label">{{ stampOutReasonLabel }}</label>
				<mat-form-field appearance="outline">
					<mat-select
						[(ngModel)]="
							this.model.stampAutomaticFillSettings[index].stampOutReasonId
						"
						[disabled]="!this.model.stampAutomaticFillSettings[index].enabled"
						(selectionChange)="updateUnsavedEdits()"
					>
						<mat-option
							*ngFor="let stampOutReason of this.stampOutReasons | async"
							[value]="stampOutReason.id"
						>
							{{ stampOutReason.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div *ngIf="item.stampAutomaticFillType === 'MinutesAfterStamp'">
					<label class="form-label">{{ minutesAfterStampLabel }}</label>
					<mat-form-field appearance="outline">
						<input
							matInput
							[(ngModel)]="
								this.model.stampAutomaticFillSettings[index].minutesAfterStamp
							"
							[disabled]="!this.model.stampAutomaticFillSettings[index].enabled"
							(change)="updateUnsavedEdits()"
						/>
					</mat-form-field>
				</div>
			</mat-expansion-panel>
		</mat-selection-list>
	`,
	styles: [
		`
			mat-form-field {
				width: 100%;
			}
		`,
	],
})
export class StampAutomaticFillSettingListComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	constructor(private readonly managementFormService: ManagementFormService) {
		super()
	}

	eventTypes: Observable<EventType[]>
	defaultEventType: EventType
	stampInReasons: Observable<StampingReason[]>
	defaultstampInReason: StampingReason
	stampOutReasons: Observable<StampingReason[]>
	defaultstampOutReason: StampingReason
	items = StampAutomaticFillType

	conflictingFillTypes = [
		StampAutomaticFillType.FromWorkShiftEnd,
		StampAutomaticFillType.FromWorkShiftLength,
		StampAutomaticFillType.ToWorkShiftEndTime,
		StampAutomaticFillType.ToWorkShiftLength,
		StampAutomaticFillType.MinutesAfterStamp,
	]

	automaticFilltypesIn = [
		StampAutomaticFillType.ToWorkShiftStartTime,
		StampAutomaticFillType.FillEmptyWorkShifts,
		StampAutomaticFillType.FillGaps,
		StampAutomaticFillType.FromWorkShiftEnd,
		StampAutomaticFillType.FromWorkShiftLength,
	]

	automaticFilltypesOut = [
		StampAutomaticFillType.ToWorkShiftEndTime,
		StampAutomaticFillType.ToWorkShiftLength,
		StampAutomaticFillType.FillEmptyWorkShifts,
		StampAutomaticFillType.FillGaps,
		StampAutomaticFillType.ToNextStampIn,
		StampAutomaticFillType.MinutesAfterStamp,
	]

	eventTypeLabel = $localize`Kirjauslaji`
	minutesAfterStampLabel = $localize`Minuutit kirjauksen jälkeen`
	stampInReasonLabel = $localize`Sisäänleimaussyy`
	stampOutReasonLabel = $localize`Ulosleimaussyy`

	ngOnInit(): void {
		this.initializeProperties()

		this.eventTypes.subscribe((eventType) => {
			this.defaultEventType = eventType[0]
		})
		this.stampInReasons.subscribe((stampInReason) => {
			this.defaultstampInReason = stampInReason[0]
		})
		this.stampOutReasons.subscribe(
			(stampOutReason) => (this.defaultstampOutReason = stampOutReason[0])
		)

		this.model.stampAutomaticFillSettings = this.createModel(
			this.model.stampAutomaticFillSettings,
			this.model.stampDirection
		)
	}

	private initializeProperties(): void {
		this.stampInReasons = this.props['stampInReasons']
		this.stampOutReasons = this.props['stampOutReasons']
		this.eventTypes = this.props['eventTypes']
	}

	getAutomaticFillTypeLabel(key: string): string {
		return StampAutomaticFillTypeLabels[key as StampAutomaticFillType]
	}

	handleConflictingCheckBoxes(index: number) {
		if (
			this.conflictingFillTypes.includes(
				this.model.stampAutomaticFillSettings[index].stampAutomaticFillType
			) &&
			this.model.stampAutomaticFillSettings[index].enabled
		) {
			this.model.stampAutomaticFillSettings.forEach(
				(stampAutomaticFillSetting: StampAutomaticFillSetting) => {
					if (
						this.conflictingFillTypes.some(
							(element) =>
								element === stampAutomaticFillSetting.stampAutomaticFillType
						) &&
						this.model.stampAutomaticFillSettings[index]
							.stampAutomaticFillType !==
							stampAutomaticFillSetting.stampAutomaticFillType &&
						stampAutomaticFillSetting.enabled
					) {
						stampAutomaticFillSetting.enabled = false
					}
				}
			)
		}
	}

	createModel(
		data: StampAutomaticFillSetting[],
		stampDirection: StampDirectionEnum
	) {
		const newData: StampAutomaticFillSetting[] = []

		const automaticFillType =
			stampDirection === StampDirectionEnum.In
				? this.automaticFilltypesIn
				: this.automaticFilltypesOut

		data.forEach((key) => {
			if (automaticFillType.includes(key.stampAutomaticFillType)) {
				if (key.id === 0) {
					key.stampingReasonId = this.model.id
					key.eventTypeId = this.defaultEventType?.id
					key.stampInReasonId = this.defaultstampInReason?.id
					key.stampOutReasonId = this.defaultstampOutReason?.id
				}

				newData.push(key)
			}
		})

		return newData
	}

	updateUnsavedEdits() {
		this.managementFormService.updateFormValue(this.model)
		this.managementFormService.updateUnsavedEdits(true)
	}
}
