import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'

@Component({
	standalone: true,
	imports: [FormlyModule, ReactiveFormsModule, CommonModule, MatSelectModule],
	selector: 'kk-color-picker',
	template: `
		<mat-select
			class="form-select"
			[formControl]="formControl"
			[attr.data-testid]="this.props.attributes?.['data-testid']"
		>
			<mat-option
				#option
				*ngFor="let item of this.colorOptions"
				[value]="item.value"
			>
				<div class="d-flex gap-2 align-items-center">
					<i class="fa-solid fa-circle" [style.color]="item.value"></i>
					<span>{{ item.text }}</span>
				</div>
			</mat-option>
		</mat-select>
	`,
	styles: [
		`
			.form-select {
				padding-right: 0.75rem !important;
				background-image: none !important;
			}
		`,
	],
})
export class ColorPickerComponent extends FieldType<FieldTypeConfig> {
	colorOptions: any[] = [
		{ value: '#1E2224', text: $localize`Musta` },
		{ value: '#1BD0D0', text: $localize`Syaani` },
		{ value: '#24A0CE', text: $localize`Sininen` },
		{ value: '#1652D8', text: $localize`Kuninkaansininen` },
		{ value: '#7936D4', text: $localize`Violetti` },
		{ value: '#B436D9', text: $localize`Fuksia` },
		{ value: '#F01189', text: $localize`Pinkki` },
		{ value: '#F43F77', text: $localize`Ruusunpunainen` },
		{ value: '#D43644', text: $localize`Punainen` },
		{ value: '#2BCBBE', text: $localize`Sinivihreä` },
		{ value: '#15B78B', text: $localize`Smaragdi` },
		{ value: '#7DB43E', text: $localize`Vihreä` },
		{ value: '#FF9031', text: $localize`kullanruskea` },
		{ value: '#FE6D4B', text: $localize`Oranssi` },
		{ value: '#582A03', text: $localize`Ruskea` },
	]
}
