import { ChangeDetectionStrategy, Component } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { DimensionLevelService } from 'src/app/core/ngrx-store/entity-services'
import { DimensionLevel } from 'src/app/core/ngrx-store/models'
import { selectRouterEntityId } from 'src/app/core/ngrx-store/router/router.selectors'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { EntityForm } from '../../models'
import { dimensionLevelSelector } from 'src/app/core/ngrx-store/dimension-level/dimension-level.selectors'
import { map } from 'rxjs'
import { Router } from '@angular/router'
import { dimensionLevelEntityType } from './dimension-level'

const levelNumberOptions = Array.from({ length: 12 }, (_, i) => ({
	value: i + 1,
	label: (i + 1).toString(),
}))

@Component({
	standalone: true,
	selector: 'kk-dimension-level-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi kohdetaso"
			i18n-title
		/>
	`,
})
export class DimensionLevelCreateNewContentComponent implements EntityForm {
	constructor(
		private readonly dimensionLevelService: DimensionLevelService,
		private readonly router: Router
	) {}

	/*
	 * Get next free number for new dimension level
	 */
	getNextFreeNumber(): number {
		const allowedNumbers = levelNumberOptions.map((option) => option.value)
		const usedNumbers = this.dimensionLevelService.entities$.pipe(
			map((dimensionLevels: DimensionLevel[]) => {
				const enabledLevels = dimensionLevels.filter((level) => !level.disabled)
				return enabledLevels.map((level) => level.orderNumber)
			})
		)

		let nextFreeNumber = 1
		usedNumbers.subscribe((used) => {
			for (let i = 1; i <= Math.max(...allowedNumbers); i++) {
				if (!used.includes(i)) {
					nextFreeNumber = i
					break
				}
			}
		})
		return nextFreeNumber
	}

	activeEntityId =
		this.dimensionLevelService.store.selectSignal(selectRouterEntityId)()
	model: Partial<DimensionLevel> = {}
	fields: FormlyFieldConfig[] = [
		{
			type: 'input',
			props: {
				label: $localize`Pääryhmä`,
				disabled: true,
				attributes: {
					'data-testid': 'management-new-dimension-level-parentdimension',
				},
			},
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					const entityMap = this.dimensionLevelService.store.selectSignal(
						dimensionLevelSelector.selectEntityMap
					)()
					const activeEntity = entityMap[Number(this.activeEntityId)]
					field.formControl?.setValue(activeEntity?.description)
				},
			},
			expressions: {
				hide: () => !this.activeEntityId,
			},
		},
		{
			key: 'description',
			type: 'input',
			props: {
				label: 'Nimi',
				required: true,
				attributes: {
					'data-testid': 'management-new-dimension-level-description',
				},
			},
		},
		{
			key: 'orderNumber',
			type: 'select',
			className: 'g-col-12',
			props: {
				label: $localize`Järjestysnumero`,
				options: this.dimensionLevelService.entities$.pipe(
					map((dimensionLevels: DimensionLevel[]) => {
						const hasOrderNumber12 = dimensionLevels.some(
							(level) => level.orderNumber === 12
						)
						if (hasOrderNumber12) {
							return levelNumberOptions.filter((option) => option.value !== 12)
						}
						return levelNumberOptions
					})
				),
				disabled: false,
				attributes: {
					'data-testid': 'management-new-dimension-level-orderNumber',
				},
			},
			hooks: {
				onInit: (field: FormlyFieldConfig) => {
					field.formControl?.setValue(this.getNextFreeNumber())
				},
			},
		},
	]

	onSubmit() {
		this.dimensionLevelService
			.add(
				{
					...this.model,
					parentId: this.activeEntityId
						? Number(this.activeEntityId)
						: undefined,
				},
				{ isOptimistic: false }
			)
			.subscribe((entity) => {
				if (entity) {
					this.router.navigate([
						'management',
						dimensionLevelEntityType.path,
						entity.id,
					])
				}
			})
	}
}
