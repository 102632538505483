import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { RouterModule } from '@angular/router'
import { FormatToTimePipe } from 'src/app/shared/pipes/format-to-time.pipe'

@Component({
	imports: [
		CommonModule,
		FormlyModule,
		ReactiveFormsModule,
		MatTableModule,
		RouterModule,
		FormatToTimePipe,
	],
	standalone: true,
	selector: 'kk-workshift-table',
	template: `
		<div class="overflow-auto">
			<table mat-table [dataSource]="props['items']" class="mat-elevation-z8">
				<ng-container matColumnDef="edit">
					<th mat-header-cell *matHeaderCellDef i18n>Toiminto</th>
					<td mat-cell *matCellDef="let item">
						<a
							class="text-decoration-underline text-success"
							[routerLink]="['/management/workshifts', item.id]"
							i18n
						>
							Muokkaa
						</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef i18n>ID</th>
					<td mat-cell *matCellDef="let item">{{ item.id }}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef i18n>Nimi</th>
					<td mat-cell *matCellDef="let item">{{ item.name }}</td>
				</ng-container>

				<ng-container matColumnDef="start">
					<th mat-header-cell *matHeaderCellDef i18n>Työvuoron alkuaika</th>
					<td mat-cell *matCellDef="let item">
						{{ item.startTime | formatToTime: false }}
					</td>
				</ng-container>

				<ng-container matColumnDef="end">
					<th mat-header-cell *matHeaderCellDef i18n>Työvuoron loppuaika</th>
					<td mat-cell *matCellDef="let item">
						{{ item.endTime | formatToTime: false }}
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columns" class="fw-semibold"></tr>
				<tr mat-row *matRowDef="let row; columns: columns"></tr>

				<tr *matNoDataRow>
					<td class="p-4" [colSpan]="columns.length" i18n>
						Ei näytettäviä rivejä
					</td>
				</tr>
			</table>
		</div>
	`,
})
export class WorkShiftTableComponent extends FieldType<FieldTypeConfig> {
	columns = ['edit', 'id', 'description', 'start', 'end']
}
