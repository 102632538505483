import { EntitySelectorsFactory } from '@ngrx/data'
import { createSelector } from '@ngrx/store'
import { BalanceLock } from './balance-lock.model'

export const balanceLockSelector =
	new EntitySelectorsFactory().create<BalanceLock>('balance-lock')

export const selectBalanceLock = createSelector(
	balanceLockSelector.selectEntities,
	(entities) => entities
)
