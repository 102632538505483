import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	Output,
	WritableSignal,
	effect,
	signal,
	untracked,
} from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core'
import { FormDataRestorationService } from './form-data-restoration/form-data-restoration.service'
import { isEqual, pick } from 'lodash-es'
import { UnsavedEditsService } from './unsaved-edits/unsaved-edits.service'
import { ToastService } from 'src/app/core/ngrx-store/entity-services'

@Component({
	selector: 'kk-form',
	templateUrl: './form.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class FormComponent implements OnDestroy, AfterViewInit {
	constructor(
		private toastService: ToastService,
		private formDataRestorationService: FormDataRestorationService,
		private unsavedEditsService: UnsavedEditsService,
		private changeDetector: ChangeDetectorRef
	) {
		/**
		 * Restore form data after formly component has been rendered.
		 * Formly updates the form-parameter that is provided to it and we should
		 * patch the restored data only after that.
		 */
		effect(() => {
			const restoredFormData =
				this.formDataRestorationService.restoredFormData()
			if (restoredFormData && this.rendered()) {
				if (hasUnsavedEdits(restoredFormData, this.model)) {
					this.form.patchValue(restoredFormData, { emitEvent: true })
				} else {
					this.toastService.showSuccess(this.alreadySavedToastMessage)
				}
				untracked(() =>
					this.formDataRestorationService.clearRestoredFormDataAfterUse()
				)
			}
		})
	}
	/**
	 * signal for checking if formly-component has been rendered.
	 */
	rendered: WritableSignal<boolean> = signal(false)

	/**
	 * Flag for rendering / destroying formly-component when model changes.
	 */
	showForm = true

	@Input() form: FormGroup = new FormGroup({})
	@Input() fields: FormlyFieldConfig[]
	@Input() useDataRestoration = false
	@Input() options: FormlyFormOptions = {}
	@Output() formValue = new EventEmitter<object>()
	@Output() unsavedEdits = new EventEmitter<boolean>()
	@Output() valid = new EventEmitter<boolean>()

	initialModel: object
	_model: object
	@Input() set model(model: object) {
		if (this._model) {
			// Changing model will emit multiple modelChange-events from formly component
			// and can cause other issues inside formly. Formly-component is destroyed
			// to avoid this.
			// PS. form reset / resetmodel-functions etc do not work for this purpose.
			this.showForm = false
			this.changeDetector.detectChanges()
			this.showForm = true
		}
		// Use structuredClone for deep cloning the objects to avoid issues
		this.initialModel = window.structuredClone(model)
		this._model = window.structuredClone(model)
		// Reset unsavedEditsService status
		this.unsavedEditsService.setUnsavedEdits(false)
	}

	get model() {
		return this._model
	}

	/**
	 * Function that is fired when formly emits modelChange event (user edits the form).
	 * @param formValue new value of the form
	 */
	formValueUpdated(formValue: object) {
		const unsavedEdits = hasUnsavedEdits(formValue, this.initialModel)

		this.unsavedEditsService.setUnsavedEdits(unsavedEdits)

		if (this.useDataRestoration && unsavedEdits) {
			this.formDataRestorationService.storeFormValue(formValue)
		} else if (this.useDataRestoration && !unsavedEdits) {
			this.formDataRestorationService.removeStoredFormDataFromLocalStorage()
		}

		this.formValue.emit(formValue)
		this.unsavedEdits.emit(unsavedEdits)
		this.valid.emit(this.form.valid)
	}

	/**
	 * Restore form data after ViewInit.
	 */
	ngAfterViewInit(): void {
		this.rendered.set(true)
	}

	ngOnDestroy(): void {
		this.form.reset()
	}

	alreadySavedToastMessage = $localize`Palautetutuissa tiedoissa ei ollut tallentamattomia muutoksia.`
}

export function hasUnsavedEdits(formValue: object, model: object) {
	const formValueKeys = Object.keys(formValue)
	const pickedModel = pick(model, formValueKeys)
	return !isEqual(formValue, pickedModel)
}
