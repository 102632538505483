import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { DurationPickerDirective } from 'src/app/shared/directives/duration-picker/duration-picker.directive'

@Component({
	imports: [
		CommonModule,
		FormlyModule,
		DurationPickerDirective,
		ReactiveFormsModule,
	],
	standalone: true,
	selector: 'kk-duration-picker',
	template: `
		<label
			*ngIf="this.props.label"
			[ngClass]="{
				'form-label': true,
				'ng-star-inserted': this.props.required
			}"
		>
			{{ this.props.label }}
			<span
				*ngIf="this.props.required"
				aria-hidden="true"
				class="ng-star-inserted"
				>*</span
			>
		</label>
		<input
			type="text"
			class="form-control"
			mbsc-input
			[formControl]="formControl"
			kkDurationPicker
		/>
	`,
})
export class DurationPickerComponent extends FieldType<FieldTypeConfig> {}
