import { Component, ChangeDetectionStrategy } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { StampingReason } from 'src/app/core/ngrx-store/models'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { StampingReasonService } from 'src/app/core/ngrx-store/entity-services'
import { EntityForm } from '../../models'
import {
	StampDirectionEnum,
	stampDirectionEnumLabels,
	StampType,
	stampTypeLabels,
} from 'src/app/core/ngrx-store/stamping-reason/stamping-reason.model'
import { Router } from '@angular/router'
import { stampingReasonEntityType } from './stamping-reason'

@Component({
	standalone: true,
	selector: 'kk-stamping-reason-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi leimaussyy"
			i18n-title
		/>
	`,
})
export class StampingReasonCreateNewComponent implements EntityForm {
	constructor(
		private readonly service: StampingReasonService,
		private readonly router: Router
	) {}

	model: Partial<StampingReason> = {
		name: '',
		public: true,
		enabled: true,
		stampDirection: StampDirectionEnum.In,
		stampType: StampType.Normal,
	}

	fields: FormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'grid gap-0 column-gap-sm-3',
			fieldGroup: [
				{
					key: 'name',
					type: 'input',
					className: 'g-col-12',
					props: {
						required: true,
						label: $localize`Leimauksen selite`,
					},
				},
				{
					key: 'stampDirection',
					type: 'select',
					className: 'g-col-12 g-col-sm-6',
					props: {
						label: $localize`Leimaussuunta`,
						options: Object.entries(stampDirectionEnumLabels).map(
							([key, label]) => ({
								value: key,
								label,
							})
						),
					},
				},
				{
					key: 'stampType',
					type: 'select',
					className: 'g-col-12 g-col-sm-6',
					props: {
						label: $localize`Leimaustyyppi`,
						options: Object.entries(stampTypeLabels).map(([key, label]) => ({
							value: key,
							label,
						})),
					},
				},
			],
		},
	]

	onSubmit() {
		this.service
			.add(
				{
					...this.model,
				},
				{ isOptimistic: false }
			)
			?.subscribe((entity) => {
				if (entity) {
					this.router.navigate([
						'management',
						stampingReasonEntityType.path,
						entity.id,
					])
				}
			})
	}
}
