import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core'
import {
	Component,
	EventEmitter,
	Output,
	Input,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core'
import { ReactiveFormsModule, FormGroup } from '@angular/forms'
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap'
import {
	ModalFooterWithDefaultButtonsComponent,
	ModalBodyComponent,
	ModalComponent,
} from 'src/app/shared/components/modal/modal.component'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormModule } from 'src/app/shared/components/form/form.module'

@Component({
	standalone: true,
	selector: 'kk-create-new-entity-modal',
	imports: [
		FormlyModule,
		FormModule,
		ReactiveFormsModule,
		FormlyBootstrapModule,
		ModalComponent,
		ModalBodyComponent,
		ModalFooterWithDefaultButtonsComponent,
	],
	template: `
		<kk-modal [title]="title">
			<kk-modal-body>
				<form [formGroup]="form" data-testid="management-create-new-modal-form">
					<formly-form
						[form]="form"
						[fields]="fields"
						[model]="model"
					></formly-form>
				</form>
			</kk-modal-body>

			<kk-modal-footer-default-content
				(submitModal)="onSubmit()"
				[submitButtonDisabled]="!form.valid"
				submitButtonText="Tallenna"
				i18n-submitButtonText
			/>
		</kk-modal>
	`,
})
export class CreateNewEntityModalComponent implements OnInit {
	constructor(
		private activeModal: NgbActiveModal,
		private changeDetectorRef: ChangeDetectorRef
	) {}
	form: FormGroup
	@Output() submitModal = new EventEmitter<void>()
	@Input() fields: FormlyFieldConfig[]
	@Input() model: object
	@Input() title: string

	// If the form is initialized without 'this.changeDetectorRef.detectChanges()',
	// a ExpressionChangedAfterItHasBeenCheckedError will occur.
	// For some reason the formly component is initialized before the submitButtonDisabled input is instantiated.
	ngOnInit(): void {
		this.form = new FormGroup({})
		this.changeDetectorRef.detectChanges()
	}

	onSubmit() {
		this.submitModal.emit()
		this.form.reset()
		this.activeModal.close()
	}
}
