import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { RouterModule } from '@angular/router'
import {
	PersonService,
	WorktimeGroupHistoryService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { map } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { WorktimeGroupHistory } from 'src/app/core/ngrx-store/worktime-group-history/worktime-group-history.model'
import { DeleteEntityModalComponent } from 'src/app/features/management/components/delete-entity-modal.component'

@Component({
	imports: [CommonModule, MatTableModule, RouterModule],
	standalone: true,
	selector: 'kk-worktime-group-history-table',
	template: `
		<div>
			<table
				mat-table
				[dataSource]="props['items']"
				class="mat-elevation-z8"
				data-testid="worktime-group-history-table"
			>
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef i18n>Id</th>
					<td mat-cell *matCellDef="let item">{{ item.id }}</td>
				</ng-container>

				<ng-container matColumnDef="groupId">
					<th mat-header-cell *matHeaderCellDef i18n>
						{{ GroupColumnHeader }}
					</th>
					<td
						mat-cell
						*matCellDef="let item"
						data-testid="worktime-group-history-table-group"
					>
						{{ GetWorktimeGroupName(item.groupId) | async }}
					</td>
				</ng-container>

				<ng-container matColumnDef="startTime">
					<th mat-header-cell *matHeaderCellDef i18n>
						{{ StartTimeColumnHeader }}
					</th>
					<td mat-cell *matCellDef="let item">
						<span
							*ngIf="dateIsNotMin(item.startTime)"
							data-testid="worktime-group-history-table-start-time"
						>
							{{ item.startTime | date: 'dd.MM.yyyy' }}
						</span>
					</td>
				</ng-container>

				<ng-container matColumnDef="delete">
					<th mat-header-cell *matHeaderCellDef i18n></th>
					<td mat-cell *matCellDef="let item">
						<button
							mat-icon-button
							color="warn"
							(click)="deleteItem(item)"
							class="btn btn-btn-elevated me-2"
							*ngIf="showDeleteButton(item)"
							data-testid="worktime-group-history-table-delete"
						>
							<div class="d-flex flex-row align-items-center">
								<i class="fa-light fa-trash pe-2 text-danger"></i>
								<p i18n class="text-danger">Poista</p>
							</div>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columns" class="fw-semibold"></tr>
				<tr mat-row *matRowDef="let row; columns: columns"></tr>

				<tr *matNoDataRow>
					<td class="p-4" [colSpan]="columns.length" i18n>
						Ei näytettäviä rivejä
					</td>
				</tr>
			</table>
		</div>
	`,
})
export class WorktimeGroupHistoryTableComponent extends FieldType<FieldTypeConfig> {
	constructor(
		private worktimeGroupService: WorktimeGroupService,
		private worktimeGroupHistoryService: WorktimeGroupHistoryService,
		private personService: PersonService,
		private modalService: NgbModal
	) {
		super()
	}
	GroupColumnHeader = $localize`Ryhmä`
	StartTimeColumnHeader = $localize`Alkaen`
	columns = ['groupId', 'startTime', 'delete']
	currentDate = new Date()

	/// Returns worktime group name by group id
	GetWorktimeGroupName(groupId: number) {
		return this.worktimeGroupService.entities$.pipe(
			map((data) => {
				const name =
					data.find((item) => item.id === groupId)?.name ?? $localize`Ei ryhmää`
				return name
			})
		)
	}

	/// Returns true if item start time is greater or equal to current date
	showDeleteButton(item: WorktimeGroupHistory) {
		if (item.startTime) {
			const itemDate = new Date(item.startTime)
			const currentDate = new Date(this.currentDate)

			// Compare only the date parts
			itemDate.setHours(0, 0, 0, 0)
			currentDate.setHours(0, 0, 0, 0)

			return itemDate >= currentDate
		}
		return false
	}

	/// Deletes item from the list
	deleteItem(item: WorktimeGroupHistory) {
		const modalRef = this.modalService.open(DeleteEntityModalComponent)
		modalRef.componentInstance.delete = () =>
			this.worktimeGroupHistoryService.delete(item.id).subscribe(() => {
				this.personService.getByKey(this.model.personId)
			})
	}

	/// Returns true if date is greater than 1900-01-01
	dateIsNotMin(d: string) {
		return new Date(d).getTime() > new Date('1900-01-01').getTime()
	}
}
