import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap'
import { FieldType } from '@ngx-formly/bootstrap/form-field'
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core'

@Component({
	imports: [CommonModule, FormlyModule, ReactiveFormsModule, NgbNavModule],
	standalone: true,
	selector: 'kk-tabs',
	template: `
		<nav
			ngbNav
			#nav="ngbNav"
			[(activeId)]="active"
			class="nav-tabs border-bottom border-1 shadow-sm"
		>
			<ng-container
				*ngFor="let tab of field.fieldGroup; let i = index"
				[ngbNavItem]="i"
			>
				<button
					type="button"
					ngbNavLink
					(click)="nav.select(i)"
					[disabled]="i === active"
				>
					<p class="fw-semibold">
						{{ tab.props!.label }}
					</p>
				</button>
				<ng-template ngbNavContent>
					<formly-field [field]="tab"></formly-field>
				</ng-template>
			</ng-container>
		</nav>
		<div [ngbNavOutlet]="nav" class="p-3"></div>
	`,
})
export class TabsComponent extends FieldType<FieldTypeConfig> {
	active = 0
}
