import { FormlyFieldConfig } from '@ngx-formly/core'

export abstract class FormlyUtils {
	/*
	 * Get a field by key
	 * @param key The key of the field to find
	 * @param fields The fields to search in
	 * @returns The field with the given key or undefined if not found
	 */
	public static getFieldByKey(
		key: string,
		fields: FormlyFieldConfig[] = []
	): FormlyFieldConfig | undefined {
		for (const field of fields) {
			if (field.key === key) {
				return field
			}

			if (field.fieldGroup && field.fieldGroup.length > 0) {
				return this.getFieldByKey(key, field.fieldGroup)
			}
		}
		return undefined
	}
}
