import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatTableModule } from '@angular/material/table'
import { RouterModule } from '@angular/router'
import {
	FlexHandling,
	flexHandlingLabels,
} from 'src/app/core/ngrx-store/event-type/event-type.model'

@Component({
	imports: [
		CommonModule,
		FormlyModule,
		ReactiveFormsModule,
		MatTableModule,
		RouterModule,
	],
	standalone: true,
	selector: 'kk-event-type-table',
	template: `
		<div class="overflow-auto">
			<table mat-table [dataSource]="props['items']" class="mat-elevation-z8">
				<ng-container matColumnDef="edit">
					<th mat-header-cell *matHeaderCellDef i18n>{{ ActionHeader }}</th>
					<td mat-cell *matCellDef="let item">
						<a
							class="text-decoration-underline text-success"
							[routerLink]="['/management/event-types', item.id]"
							i18n
						>
							{{ ModifyLabel }}
						</a>
					</td>
				</ng-container>

				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef i18n>ID</th>
					<td mat-cell *matCellDef="let item">{{ item.id }}</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef i18n>{{ NameHeader }}</th>
					<td mat-cell *matCellDef="let item">{{ item.name }}</td>
				</ng-container>

				<ng-container matColumnDef="flexHandling">
					<th mat-header-cell *matHeaderCellDef i18n>{{ FlexHandlingHeader }}</th>
					<td mat-cell *matCellDef="let item">
						{{ getFlexHandlingLabel(item.flexHandling) }}
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columns" class="fw-semibold"></tr>
				<tr mat-row *matRowDef="let row; columns: columns"></tr>

				<tr *matNoDataRow>
					<td class="p-4" [colSpan]="columns.length" i18n>
						{{ EmptyListLabel }}
					</td>
				</tr>
			</table>
		</div>
	`,
})
export class EventTypeTableComponent extends FieldType<FieldTypeConfig> {
	columns = ['edit', 'id', 'name', 'flexHandling']

	ActionHeader = $localize`Toiminto`
	NameHeader = $localize`Nimi`
	FlexHandlingHeader = $localize`Liukumakäsittely`

	ModifyLabel = $localize`Muokkaa`
	EmptyListLabel = $localize`Ei näytettäviä rivejä`

	getFlexHandlingLabel(flexHandling: FlexHandling) {
		return flexHandlingLabels[flexHandling]
	}
}
