export enum HistoryEventType {
	Created = 'Created',
	Modified = 'Modified',
	MarkedReady = 'MarkedReady',
	CancelledReadyMark = 'CancelledReadyMark',
	MarkedAccepted = 'MarkedAccepted',
	CancelledAcceptedMark = 'CancelledAcceptedMark',
	Rejected = 'Rejected',
}

export const historyEventTypeLabels: { [key in HistoryEventType]: string } = {
	Created: $localize`Luotu`,
	Modified: $localize`Muokattu`,
	MarkedReady: $localize`Valmismerkitty`,
	CancelledReadyMark: $localize`Valmismerkintä peruttu`,
	MarkedAccepted: $localize`Hyväksytty`,
	CancelledAcceptedMark: $localize`Hyväksyntä peruttu`,
	Rejected: $localize`Hylätty`,
}

export interface HistoryEvent {
	id: number
	personId: number
	eventId?: number
	realizedWorkshiftId?: number
	dayId?: number
	weekId?: number
	eventType: HistoryEventType
	eventTime: string
	description: string
}
