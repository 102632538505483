import { Component, OnInit } from '@angular/core'
import {
	CdkDragDrop,
	CdkDropList,
	CdkDrag,
	CdkDragPlaceholder,
	moveItemInArray,
} from '@angular/cdk/drag-drop'
import {
	FieldType,
	FieldTypeConfig,
	FormlyFieldProps,
	FormlyModule,
} from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

export interface ListItem {
	value: string
	label: string
}

interface SortableListProps extends FormlyFieldProps {
	items: ListItem[]
}

@Component({
	imports: [
		CommonModule,
		FormlyModule,
		ReactiveFormsModule,
		CdkDropList,
		CdkDrag,
		CdkDragPlaceholder,
	],
	standalone: true,
	selector: 'kk-sortable-list',
	template: `
		<div
			cdkDropList
			(cdkDropListDropped)="drop($event)"
			[formlyAttributes]="field"
		>
			@for (item of items; track item.value) {
				<div cdkDrag cdkDragLockAxis="y" class="gap-3">
					<div *cdkDragPlaceholder></div>
					<i class="fa-regular fa-grip-lines"></i>
					<p>{{ item.label }}</p>
				</div>
			}
		</div>
	`,
})
export class SortableListComponent
	extends FieldType<FieldTypeConfig<SortableListProps>>
	implements OnInit
{
	items: ListItem[]

	ngOnInit(): void {
		this.items = [...this.props.items]
		const values = this.formControl.value as string[]

		if (!values) {
			return
		}

		this.items.sort((a, b) => values.indexOf(a.value) - values.indexOf(b.value))
	}

	drop(event: CdkDragDrop<ListItem[]>) {
		moveItemInArray(this.items, event.previousIndex, event.currentIndex)
		this.setValue()
	}

	setValue() {
		this.formControl.setValue(this.items.map((item) => item.value))
	}
}
