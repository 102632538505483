import { Pipe, PipeTransform } from '@angular/core'
import { DateUtils } from 'src/app/core/utils/date-utils'

@Pipe({
	name: 'formatToTime',
	pure: true,
	standalone: true,
})
export class FormatToTimePipe implements PipeTransform {
	transform(
		date: string | Date | undefined,
		convertUTC: boolean = true
	): string {
		return DateUtils.formatToTime(date, convertUTC)
	}
}
