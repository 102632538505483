import { Component, OnInit } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { MbscSelectModule } from '@mobiscroll/angular'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { MatSelectModule } from '@angular/material/select'
import { Observable } from 'rxjs'

export interface SelectItem {
	value: string
	label: string
}

@Component({
	standalone: true,
	imports: [
		FormlyModule,
		ReactiveFormsModule,
		CommonModule,
		MbscSelectModule,
		MatSelectModule,
	],
	selector: 'kk-multiselect',
	template: `
		<mat-select
			class="form-select"
			[placeholder]="placeholder"
			multiple
			[formControl]="formControl"
			(selectionChange)="updatePlaceholder()"
		>
			<mat-select-trigger>
				{{ this.placeholder }}
			</mat-select-trigger>
			<mat-option
				#option
				*ngFor="let item of (this.items | async) ?? []"
				[value]="item.value"
			>
				<span [class.multiselect-is-selected]="option.selected">
					{{ item.value }}. {{ item.label }}
				</span>
			</mat-option>
		</mat-select>
	`,
	styles: [
		`
			::ng-deep .cdk-overlay-container {
				z-index: 9999;
			}
		`,
	],
})
export class MultiSelectComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	placeholder = ''
	items: Observable<SelectItem[]>

	ngOnInit(): void {
		this.items = this.props['items']
		this.updatePlaceholder()
	}

	updatePlaceholder() {
		const placeHolderStringSingle = $localize`Valinta`
		const placeHolderStringMultiple = $localize`Valintaa`
		this.placeholder =
			this.formControl?.value && this.formControl?.value.length > 0
				? this.formControl.value?.length > 1
					? `${this.formControl.value?.length} ${placeHolderStringMultiple}`
					: `${this.formControl.value?.length} ${placeHolderStringSingle}`
				: `0 ${placeHolderStringMultiple}`
	}
}
