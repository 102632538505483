<ng-container
	*ngrxLet="{
		user: currentUser$ | ngrxPush,
		environment: currentEnvironment()
	} as vm"
>
	<div ngbNav class="navbar navbar-expand-lg p-0 bg-dark">
		<div
			class="container-fluid p-0 justify-content-lg-between h-100 align-content-center"
		>
			<button
				(click)="mobileNavToggleHandler()"
				class="navbar-toggler text-white d-block d-lg-none h-100 border-0 shadow-none"
				data-testid="navbar-toggler"
			>
				<i class="fa-regular fa-bars text-white"></i>
			</button>
			<a class="navbar-brand d-flex h-100 p-3 m-0" [routerLink]="'.'">
				<img
					class="mh-100"
					src="app/assets/images/kellokorttiLogo.png"
					alt="Kellokortti"
					data-testid="header-logo"
				/>
			</a>
			<div class="d-flex h-100">
				<ul class="nav align-items-center">
					<kk-header-dropdown
						class="h-100 border-start border-secondary"
						[id]="'header-settings-dropdown'"
						[options]="settingsOptions()"
						data-testid="settings-dropdown"
					>
						<i class="fa-light fa-gear text-secondary"></i>
					</kk-header-dropdown>
					<kk-header-dropdown
						class="h-100 border-start border-secondary"
						[id]="'header-environment-dropdown'"
						[options]="companyOptions"
						data-testid="environment-dropdown"
					>
						<div class="d-none d-sm-flex flex-column align-items-start">
							<p class="text-white" data-testid="environment-name">
								{{ vm.environment?.name }}
							</p>
							<p class="text-secondary" data-testid="environment-vat-unit">
								{{ vm.environment?.vat }} /
								{{ vm.environment?.unit }}
							</p>
						</div>
						<i class="d-block d-sm-none fa-light fa-user text-secondary"></i>
					</kk-header-dropdown>
					<kk-header-dropdown
						class="h-100 border-start border-secondary"
						[id]="'header-profile-dropdown'"
						[options]="profileOptions"
						data-testid="profile-dropdown"
					>
						<div class="d-none d-sm-flex flex-column align-items-start">
							<p class="text-white" data-testid="header-name">
								{{ vm.user?.shortname }}
							</p>
							<p class="text-secondary" data-testid="header-email">
								{{ vm.user?.email }}
							</p>
						</div>
						<i class="d-block d-sm-none fa-light fa-user text-secondary"></i>
					</kk-header-dropdown>
				</ul>
			</div>
		</div>
	</div>
</ng-container>
