import { Injectable, InjectionToken, inject } from '@angular/core'
import {
	EventTypeService,
	StampingReasonService,
	WorktimeGroupService,
} from 'src/app/core/ngrx-store/entity-services'
import { StampingReason } from 'src/app/core/ngrx-store/models'
import {
	EntityService,
	EntityType,
	SidebarItem,
	entitySelectorFactory,
} from '../../models'
import { stampingReasonSelector } from 'src/app/core/ngrx-store/stamping-reason/stamping-reason.selectors'
import { StampingReasonCreateNewComponent } from './stamping-reason-create-new.component'
import { map, of } from 'rxjs'
import {
	checkboxField,
	emptyRow,
	numberField,
	textField,
} from 'src/app/shared/components/form/formly-field'
import {
	StampDirectionEnum,
	stampDirectionEnumLabels,
	stampTypeLabels,
} from 'src/app/core/ngrx-store/stamping-reason/stamping-reason.model'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { EntityTypeGroup } from '../../components/entity-type-list/entity-type-list.model'

export const selectStampingReasonEntity = entitySelectorFactory(
	stampingReasonSelector
)

@Injectable({ providedIn: 'root' })
export class StampingReasonEntityService extends EntityService<StampingReason> {
	formatDescriptionsToTitle(inDescription?: string, outDescription?: string) {
		if (inDescription && outDescription) {
			return `${inDescription} - ${outDescription}`
		} else if (inDescription) {
			return inDescription
		} else if (outDescription) {
			return outDescription
		} else {
			return ''
		}
	}

	override mapEntityToSidebarItem = (
		stampingReason: StampingReason
	): SidebarItem => {
		return {
			id: stampingReason.id.toString(),
			title: of(this.formatDescriptionsToTitle(stampingReason.name)),
		}
	}

	constructor(
		private stampingReasonService: StampingReasonService,
		private worktimeGroupService: WorktimeGroupService,
		private eventTypeService: EventTypeService
	) {
		super(stampingReasonService, selectStampingReasonEntity)

		this.formlyFields = [
			{
				type: 'tabs',
				fieldGroup: [
					{
						props: { label: $localize`Perustiedot` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							textField('name', $localize`Nimi`, 'g-col-12'),
							{
								key: 'stampDirection',
								type: 'select',
								className: 'g-col-6',
								props: {
									label: $localize`Leimaussuunta`,
									options: Object.entries(stampDirectionEnumLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-stamping-reason-stamp-direction',
									},
								},
							},
							{
								key: 'stampType',
								type: 'select',
								className: 'g-col-6',
								props: {
									label: $localize`Leimaustyyppi`,
									options: Object.entries(stampTypeLabels).map(
										([key, label]) => ({
											value: key,
											label,
										})
									),
									attributes: {
										'data-testid': 'management-stamping-reason-stamp-type',
									},
								},
							},
							{
								key: 'worktimeGroups',
								type: 'multiselect',
								className: 'g-col-12',
								props: {
									label: $localize`Rajaa valituille työaikaryhmille`,
									multiple: true,
									items: this.worktimeGroupService.entities$.pipe(
										map((worktimeGroups) =>
											worktimeGroups.map((group) => ({
												value: group.id,
												label: group.name,
											}))
										)
									),
									attributes: {
										'data-testid': 'management-stamping-reason-worktimeGroups',
									},
								},
							},
							numberField('orderNumber', $localize`Näyttöjärjestys`),
							emptyRow,
							checkboxField('enabled', $localize`Käytössä`),
							checkboxField('public', $localize`Julkinen tieto`),
							{
								key: 'endsWorkShift',
								type: 'checkbox',
								className: 'g-col-12',
								props: {
									label: $localize`Päättää työvuoron`,
									attributes: {
										'data-testid': 'management-stamping-reason-endsWorkShift',
									},
								},
								expressions: {
									hide: (field: FormlyFieldConfig) =>
										field.model.stampDirection === 'In',
								},
							},
						],
					},
					{
						props: { label: $localize`Automaattitäytöt` },
						fieldGroupClassName: 'grid',
						fieldGroup: [
							{
								key: 'stampAutomaticFillSettings',
								type: 'stamp-automatic-fill-setting-list',
								className: 'g-col-12',
								props: {
									label: $localize`Automaattitäytöt`,
									eventTypes: this.eventTypeService.entities$,
									stampInReasons: this.stampingReasonService.entities$.pipe(
										map((reasons) =>
											reasons.filter(
												(reason) =>
													reason.enabled &&
													reason.stampDirection === StampDirectionEnum.In
											)
										)
									),
									stampOutReasons: this.stampingReasonService.entities$.pipe(
										map((reasons) =>
											reasons.filter(
												(reason) =>
													reason.enabled &&
													reason.stampDirection === StampDirectionEnum.Out
											)
										)
									),
								},
							},
						],
					},
				],
			},
		]
	}
}

export const stampingReasonEntityType: EntityType = {
	mainGroup: EntityTypeGroup.Worktime,
	title: $localize`Leimaussyyt`,
	path: 'stamping-reasons',
	serviceToken: new InjectionToken<StampingReasonEntityService>(
		'stamping-reasons',
		{
			factory: () => inject(StampingReasonEntityService),
		}
	),
	createNewContentComponent: StampingReasonCreateNewComponent,
	featureGate: ['management-stamping-reason'],
}
