import { Component } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'

@Component({
	selector: 'kk-button',
	template: `
		<div>
			<button
				[type]="props.type"
				[disabled]="props.disabled"
				[className]="props['className']"
				(click)="onClick($event)"
			>
				{{ props.label }}
			</button>
		</div>
	`,
})
export class ButtonComponent extends FieldType<FieldTypeConfig> {
	onClick($event: Event) {
		if (this.props['onClick']) {
			this.props['onClick']($event)
		}
	}
}
