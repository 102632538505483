import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
} from '@ngrx/data'
import { Observable, shareReplay } from 'rxjs'
import { Environment } from 'src/app/core/ngrx-store/environment/environment.model'
import * as storage from 'src/app/core/services/session/local-storage-helpers'

@Injectable()
export class EnvironmentService extends EntityCollectionServiceBase<Environment> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		private http: HttpClient
	) {
		super('Environment', serviceElementsFactory)
	}

	/**
	 * Get version info from the server and save it to local storage.
	 */
	public getVersionInfo(): Observable<string> {
		const request = this.http
			.get<string>('environments/VersionInfo')
			.pipe(shareReplay())

		request?.subscribe((version) => {
			storage.saveVersionInfoToLocalStorage(version)
		})

		return request
	}
}
