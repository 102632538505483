export abstract class SortUtils {
	// Sorts an array of objects by a key in natural order
	public static naturalSort<T>(array: T[], key: keyof T): T[] {
		const rx = /(\d+)|(\D+)/g
		const rd = /\d+/

		return array.sort((a, b) => {
			const aValue = String(a[key]).toLowerCase().match(rx)
			const bValue = String(b[key]).toLowerCase().match(rx)

			if (!aValue || !bValue) {
				return 0
			}

			while (aValue.length && bValue.length) {
				const a1 = aValue.shift()
				const b1 = bValue.shift()

				if (!a1 || !b1) {
					return 0
				}

				const isANumeric = rd.test(a1)
				const isBNumeric = rd.test(b1)

				if (isANumeric || isBNumeric) {
					if (!isANumeric) return 1
					if (!isBNumeric) return -1
					if (a1 !== b1) return parseInt(a1, 10) - parseInt(b1, 10)
				} else if (a1 !== b1) {
					return a1 > b1 ? 1 : -1
				}
			}

			return aValue.length - bValue.length
		})
	}

	// Compares two strings in a case-insensitive way
	public static compareStrings(strA?: string, strB?: string): number {
		const a = strA?.toLowerCase() || ''
		const b = strB?.toLowerCase() || ''
		if (a < b) {
			return -1
		}
		if (a > b) {
			return 1
		}
		return 0
	}
}
