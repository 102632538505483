import { AuthService } from 'src/app/core/services/auth/auth.service'
import { Component, computed, EventEmitter, Output } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { LetDirective, PushPipe } from '@ngrx/component'
import {
	DimensionLevelService,
	EventTypeService,
	StampingReasonService,
	UserService,
	WorkShiftService,
	WorktimeGroupService,
	PersonService,
	EnvironmentService,
} from 'src/app/core/ngrx-store/entity-services'
import {
	DropdownOption,
	HeaderDropdownComponent,
} from './header-dropdown/header-dropdown.component'
import { SessionService } from 'src/app/core/services/session/session.service'
import * as storage from 'src/app/core/services/session/local-storage-helpers'
import { of } from 'rxjs'
import { toSignal } from '@angular/core/rxjs-interop'
import test from '@playwright/test'

/**
 * The upper bar of the layout containing the logo,
 * navigation menu toggle and other essential functions.
 */

@Component({
	selector: 'kk-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		HeaderDropdownComponent,
		LetDirective,
		PushPipe,
	],
})
export class HeaderComponent {
	currentUser$ = this.userService.getCurrentUser()
	eventTypes$ = this.eventTypeService.getAll()
	stampingReasons$ = this.stampingReasonService.getAll()
	worktimeGroups$ = this.worktimeGroupService.getAll()
	workShifts$ = this.workShiftService.getAll()
	dimensionLevels$ = this.dimensionLevelService.getAll()
	persons$ = this.PersonService.getAll()
	currentEnvironment = this.sessionService.environment
	versionInfo = toSignal(this.checkVersionInfo())

	constructor(
		private authService: AuthService,
		private router: Router,
		private userService: UserService,
		private eventTypeService: EventTypeService,
		private stampingReasonService: StampingReasonService,
		private worktimeGroupService: WorktimeGroupService,
		private workShiftService: WorkShiftService,
		private dimensionLevelService: DimensionLevelService,
		private PersonService: PersonService,
		private sessionService: SessionService,
		private environmentService: EnvironmentService
	) {}

	/** Toggle for hamburger menu */
	@Output()
	toggleMobileNav = new EventEmitter<void>()

	settingsOptions = computed(() => {
		return [
			{
				title: 'Versio ' + this.versionInfo(),
				action: () => () => {},
			},
		]
	})

	companyOptions: DropdownOption[] = [
		{
			title: $localize`Vaihda yritys`,
			action: () => {
				this.router.navigate(['settings']).catch((err) => alert(err))
			},
		},
	]

	profileOptions: DropdownOption[] = [
		{
			title: $localize`Kirjaudu ulos`,
			action: () => {
				this.logout()
			},
		},
	]

	public logout(): void {
		this.authService.logout()
	}

	mobileNavToggleHandler() {
		this.toggleMobileNav.emit()
	}

	/**
	 * Get version info from local storage or fetch it from server if it's not found.
	 * @returns observable of version info
	 */
	checkVersionInfo() {
		const versionInfo = storage.getVersionInfoFromLocalStorage()
		if (!versionInfo) {
			return this.environmentService.getVersionInfo()
		} else {
			return of(versionInfo)
		}
	}
}
