@if (showComponent) {
	<hr class="g-col-12" />
	<div class="row mb-3 mt-4">
		<input
			type="text"
			[placeholder]="this.placeholderSearch"
			[matAutocomplete]="auto"
			[formControl]="autoCompleteFormControl"
			class="form-select"
			data-testid="autocomplete-input"
		/>
		<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
			<mat-option></mat-option>
			<mat-option
				*ngFor="let option of filteredDimensions"
				[value]="option"
				(onSelectionChange)="
					$event.source.selected
						? setSelection($event.source.value, undefined)
						: null
				"
				multiple="false"
			>
				{{ getDimensionFullPath(option.id) }}
			</mat-option>
		</mat-autocomplete>
	</div>

	<div class="grid gap-3 mb-4">
		<div
			class="g-col-12 g-col-lg-6"
			*ngFor="let item of this.dimensionLevels"
			[ngClass]="{ hidden: item.hidden }"
		>
			<div>
				<label class="form-label" [for]="'select-' + item.id">{{
					item.description
				}}</label>
				<mat-select
					id="select-{{ item.id }}"
					class="form-select"
					[formControl]="getForm(item.id) || createFormControl(item.id)"
					(selectionChange)="setSelection($event.value, item.id)"
					[placeholder]="this.placeholder + ' ' + item.description"
				>
					<mat-option selected></mat-option>
					<mat-option
						*ngFor="let dimension of getDimensionsFilteredByParentId(item.id)"
						[value]="dimension"
						[disabled]="
							item.parentId !== null && !isParentLevelSelected(item.parentId)
						"
					>
						{{ getDimensionName(dimension) }}
					</mat-option>
				</mat-select>
			</div>
		</div>
	</div>
} @else {
	<kk-loading-spinner [visible]="loading()" />
}
