import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap'
import { TabsComponent } from './types/tabs/tabs.component'
import { FormlyModule } from '@ngx-formly/core'
import { FormComponent } from './form.component'
import { DateTimePickerComponent } from './types/datetime-picker/datetime-picker.component'
import { TimePickerComponent } from './types/time-picker/time-picker.component'
import { WorkShiftTableComponent } from './types/workshift-table/workshift-table.component'
import { EventTypeTableComponent } from './types/event-type-table/event-type-table.component'
import { NgIf } from '@angular/common'
import { TooltipWrapperComponent } from './wrappers/tooltip/tooltip.component'
import { tooltipExtension } from './wrappers/tooltip/tooltip.extension'
import { ColorPickerComponent } from './types/color-picker/color-picker.component'
import { SortableListComponent } from './types/sortable-list/sortable-list.component'
import { WorktimeGroupHistoryTableComponent } from './types/worktime-group-history-table/worktime-group-history-table.component'
import { WorktimeGroupChangeButtonComponent } from './types/worktime-group-history-button/worktime-group-history-button.component'
import { ButtonComponent } from './types/button/button-type.component'
import { MultiSelectComponent } from './types/multiselect/multiselect.component'
import { StampAutomaticFillSettingListComponent } from './types/stamp-automatic-fill-setting-list/stamp-automatic-fill-setting-list.component'
import { DimensionSelectComponent } from './types/dimension-select/dimension-select.component'
import { EventTypeWorktimeReasonMappingComponent } from './types/event-type-worktime-reason-mapping/event-type-worktime-reason-mapping'
import { MbscSelectComponent } from './types/mbsc-select/mbsc-select.component'
import { DurationPickerComponent } from './types/duration-picker/duration-picker.component'

@NgModule({
	declarations: [FormComponent],
	exports: [FormComponent, FormlyModule],
	imports: [
		NgIf,
		ReactiveFormsModule,
		FormlyBootstrapModule,
		FormlyModule.forChild({
			types: [
				{
					name: 'datetime',
					component: DateTimePickerComponent,
					wrappers: ['form-field'],
				},
				{
					name: 'time',
					component: TimePickerComponent,
					wrappers: ['form-field'],
				},
				{
					name: 'color',
					component: ColorPickerComponent,
					wrappers: ['form-field'],
				},
				{
					name: 'tabs',
					component: TabsComponent,
				},
				{
					name: 'duration-picker',
					component: DurationPickerComponent,
				},
				{
					name: 'workshift-table',
					component: WorkShiftTableComponent,
				},
				{
					name: 'event-type-table',
					component: EventTypeTableComponent,
				},
				{
					name: 'sortable-list',
					component: SortableListComponent,
				},
				{
					name: 'worktimeGroupChangeButton',
					component: WorktimeGroupChangeButtonComponent,
				},
				{
					name: 'worktime-group-history-table',
					component: WorktimeGroupHistoryTableComponent,
				},
				{
					name: 'button',
					component: ButtonComponent,
				},
				{
					name: 'multiselect',
					component: MultiSelectComponent,
					wrappers: ['form-field'],
				},
				{
					name: 'stamp-automatic-fill-setting-list',
					component: StampAutomaticFillSettingListComponent,
				},
				{
					name: 'mbsc-select',
					component: MbscSelectComponent,
					wrappers: ['form-field'],
				},
				{
					name: 'dimension-select',
					component: DimensionSelectComponent,
				},
				{
					name: 'event-type-worktime-reason-mapping',
					component: EventTypeWorktimeReasonMappingComponent,
				},
			],
			validators: [
				{
					name: 'requiredCheckbox',
					validation: (control) => (control.value ? null : { required: true }),
				},
			],
			validationMessages: [
				{
					name: 'required',
					message: $localize`Pakollinen tieto`,
				},
			],
			extras: {
				resetFieldOnHide: false,
			},
			wrappers: [{ name: 'tooltip', component: TooltipWrapperComponent }],
			extensions: [
				{ name: 'tooltip', extension: { postPopulate: tooltipExtension } },
			],
		}),
	],
})
export class FormModule {}
