import { Component, OnDestroy, OnInit } from '@angular/core'
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core'
import { MbscModule, MbscPopupDisplay } from '@mobiscroll/angular'
import { Observable, Subscription, isObservable } from 'rxjs'
import { ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
@Component({
	selector: 'kk-mbsc-select',
	standalone: true,
	imports: [CommonModule, MbscModule, ReactiveFormsModule],
	template: `
		<mbsc-select
			[data]="data"
			[display]="props.display"
			[filter]="props.filter"
			[labelStyle]="props.labelStyle"
			[inputStyle]="props.inputStyle ?? 'outline'"
			[placeholder]="props.placeholder"
			[formControl]="formControl"
		>
		</mbsc-select>
	`,
})
export class MbscSelectComponent
	extends FieldType<FieldTypeConfig<MbscSelectComponentProps>>
	implements OnInit, OnDestroy
{
	data?: MbscSelectOption[]
	private subscription: Subscription

	ngOnInit(): void {
		if (isObservable(this.props.data)) {
			this.subscription = this.props.data.subscribe((items) => {
				this.data = items
			})
		} else {
			this.data = this.props.data
		}
	}
	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe()
		}
	}
}
interface MbscSelectComponentProps extends FormlyFieldProps {
	filter?: boolean
	data?: MbscSelectOption[] | Observable<MbscSelectOption[]>
	display?: MbscPopupDisplay
	labelStyle?: 'floating' | 'stacked' | 'inline'
	inputStyle?: 'underline' | 'outline' | 'box'
}
export interface MbscSelectOption {
	text: string
	value: unknown
	group?: string
}
