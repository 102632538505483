import { createSelector } from '@ngrx/store'
import { allEntityTypes } from './configurations/configurations'
import { personSelector } from 'src/app/core/ngrx-store/person/person.selectors'
import { worktimeGroupSelector } from 'src/app/core/ngrx-store/worktime-group/worktime-group.selectors'
import { personEntityType } from './configurations/person/person'
import { worktimeGroupEntityType } from './configurations/worktime-group/worktime-group'
import { eventTypeEntityType } from './configurations/event-type/event-type'
import { eventTypeSelector } from 'src/app/core/ngrx-store/event-type/event-type.selectors'
import { selectRouterEntityTypePath } from 'src/app/core/ngrx-store/router/router.selectors'

import { workShiftEntityType } from './configurations/workshift/workshift'
import { workShiftSelector } from 'src/app/core/ngrx-store/workshift/workshift.selectors'
import { dimensionSelector } from 'src/app/core/ngrx-store/dimension/dimension.selectors'
import { dimensionEntityType } from './configurations/dimension/dimension'
import { dimensionLevelSelector } from 'src/app/core/ngrx-store/dimension-level/dimension-level.selectors'
import { dimensionLevelEntityType } from './configurations/dimension-level/dimension-level'
import { balanceLockSelector } from 'src/app/core/ngrx-store/balance-lock/balance-lock.selectors'
import { balanceLock } from './configurations/balance-lock/balance-lock'

export const selectEntityType = createSelector(
	selectRouterEntityTypePath,
	(entityTypePath) => {
		// createSelector is outside of injection context so we need to use allEntityTypes here :(
		const entityType = allEntityTypes.find(
			(entityType) => entityType.path === entityTypePath
		)
		return entityType ?? null
	}
)

export const selectLoading = createSelector(
	personSelector.selectLoading,
	worktimeGroupSelector.selectLoading,
	balanceLockSelector.selectLoading,
	eventTypeSelector.selectLoading,
	workShiftSelector.selectLoading,
	dimensionSelector.selectLoading,
	dimensionLevelSelector.selectLoading,
	selectEntityType,
	(
		personLoading,
		worktimeGroupLoading,
		balanceLockLoading,
		eventTypeLoading,
		workShiftLoading,
		dimensionLoading,
		dimensionLevelLoading,
		entityType
	) => {
		switch (entityType?.path) {
			case personEntityType.path:
				return personLoading
			case worktimeGroupEntityType.path:
				return worktimeGroupLoading
			case balanceLock.path:
				return balanceLockLoading
			case eventTypeEntityType.path:
				return eventTypeLoading
			case workShiftEntityType.path:
				return workShiftLoading
			case dimensionEntityType.path:
				return dimensionLoading
			case dimensionLevelEntityType.path:
				return dimensionLevelLoading
			default:
				return false
		}
	}
)
