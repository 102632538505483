import { Component, ChangeDetectionStrategy } from '@angular/core'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { EntityForm } from '../../models'
import { CreateNewEntityModalComponent } from '../../components/create-new-entity-modal/create-new-entity-modal.component'
import { WorkShift } from 'src/app/core/ngrx-store/workshift/workshift.model'
import { WorkShiftService } from 'src/app/core/ngrx-store/workshift/workshift.service'
import { map } from 'rxjs'
import { requiredTextField } from 'src/app/shared/components/form/formly-field'
import { Router } from '@angular/router'
import { workShiftEntityType } from './workshift'

@Component({
	standalone: true,
	selector: 'kk-workshift-create-new',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CreateNewEntityModalComponent],
	template: `
		<kk-create-new-entity-modal
			(submitModal)="onSubmit()"
			[model]="model"
			[fields]="fields"
			title="Uusi työvuoro"
			i18n-title
		/>
	`,
})
export class WorkShiftCreateNewContentComponent implements EntityForm {
	constructor(
		private readonly workShiftService: WorkShiftService,
		private readonly router: Router
	) {}

	model: Partial<WorkShift> = {
		name: '',
	}

	fields: FormlyFieldConfig[] = [
		requiredTextField('name', $localize`Nimi`, 'g-col-12'),
		{
			key: 'number',
			type: 'select',
			props: {
				label: $localize`Mallipohja`,
				options: this.workShiftService.entities$.pipe(
					map((workShifts) => [
						{
							value: undefined,
							label: '-',
						},
						...workShifts.map((workShift) => ({
							value: workShift.number,
							label: workShift.name,
						})),
					])
				),
			},
		},
	]

	onSubmit() {
		this.workShiftService
			.add({ ...this.model }, { isOptimistic: false })
			?.subscribe((entity) => {
				if (entity) {
					this.router.navigate([
						'management',
						workShiftEntityType.path,
						entity.id,
					])
				}
			})
	}
}
