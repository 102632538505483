/**
 * Class that does the same as .net TimeSpan, except accuracy is in millisecond.
 * Keep this class immutable
 */
export class TimeSpan {
	public static readonly MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000
	public static readonly MILLISECONDS_PER_HOUR = 60 * 60 * 1000
	public static readonly MILLISECONDS_PER_MINUTE = 60 * 1000
	public static readonly MILLISECONDS_PER_SECOND = 1000
	public static readonly ZERO = new TimeSpan(0, 0, 0, 0, 0)

	private readonly _totalMilliseconds: number

	public get days(): number {
		return Math.floor(this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_DAY)
	}
	public get hours(): number {
		return Math.floor(
			(this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_HOUR) % 24
		)
	}
	public get minutes(): number {
		return Math.floor(
			(this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_MINUTE) % 60
		)
	}
	public get seconds(): number {
		return Math.floor(
			(this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_SECOND) % 60
		)
	}
	public get milliseconds(): number {
		return this._totalMilliseconds % TimeSpan.MILLISECONDS_PER_SECOND
	}

	public get totalDays(): number {
		return this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_DAY
	}
	public get totalHours(): number {
		return this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_HOUR
	}
	public get totalMinutes(): number {
		return this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_MINUTE
	}
	public get totalSeconds(): number {
		return this._totalMilliseconds / TimeSpan.MILLISECONDS_PER_SECOND
	}
	public get totalMilliseconds(): number {
		return this._totalMilliseconds
	}

	constructor(
		days: number,
		hours: number,
		minutes: number,
		seconds: number,
		milliseconds: number
	) {
		this._totalMilliseconds =
			days * TimeSpan.MILLISECONDS_PER_DAY +
			hours * TimeSpan.MILLISECONDS_PER_HOUR +
			minutes * TimeSpan.MILLISECONDS_PER_MINUTE +
			seconds * TimeSpan.MILLISECONDS_PER_SECOND +
			milliseconds
	}

	public add(timeSpan: TimeSpan): TimeSpan {
		return TimeSpan.fromMilliseconds(
			this.totalMilliseconds + timeSpan._totalMilliseconds
		)
	}
	public addDays(days: number) {
		return this.add(new TimeSpan(days, 0, 0, 0, 0))
	}
	public addHours(hours: number) {
		return this.add(new TimeSpan(0, hours, 0, 0, 0))
	}
	public addMinutes(minutes: number) {
		return this.add(new TimeSpan(0, 0, minutes, 0, 0))
	}
	public addSeconds(seconds: number) {
		return this.add(new TimeSpan(0, 0, 0, seconds, 0))
	}
	public addMilliseconds(milliseconds: number) {
		return this.add(new TimeSpan(0, 0, 0, 0, milliseconds))
	}
	public substract(timeSpan: TimeSpan): TimeSpan {
		return TimeSpan.fromMilliseconds(
			this.totalMilliseconds - timeSpan.totalMilliseconds
		)
	}
	public isLessThan(other: TimeSpan): boolean {
		return this.totalMilliseconds < other.totalMilliseconds
	}
	public isLessOrEqual(other: TimeSpan): boolean {
		return this.totalMilliseconds <= other.totalMilliseconds
	}
	public isGreaterThan(other: TimeSpan): boolean {
		return this.totalMilliseconds > other.totalMilliseconds
	}
	public isGreaterOrEqual(other: TimeSpan): boolean {
		return this.totalMilliseconds >= other.totalMilliseconds
	}
	public isEqual(other: TimeSpan): boolean {
		return this.totalMilliseconds === other.totalMilliseconds
	}
	public static fromDays(days: number): TimeSpan {
		return new TimeSpan(days, 0, 0, 0, 0)
	}
	public static fromHours(hours: number): TimeSpan {
		return new TimeSpan(0, hours, 0, 0, 0)
	}
	public static fromMinutes(minutes: number): TimeSpan {
		return new TimeSpan(0, 0, minutes, 0, 0)
	}
	public static fromSeconds(seconds: number): TimeSpan {
		return new TimeSpan(0, 0, 0, seconds, 0)
	}
	public static fromMilliseconds(milliseconds: number): TimeSpan {
		return new TimeSpan(0, 0, 0, 0, milliseconds)
	}

	/*
		returns a string in form of 'hh:mm' where 'hh' are total of complete hours. 
		'mm' is remaining complete minutes
	*/
	public toStringHHmm(): string {
		let str = ''
		if (this.isLessThan(TimeSpan.ZERO)) str += '-'

		str += Math.abs(Math.trunc(this.totalHours))
		str += ':'
		if (Math.abs(this.minutes) < 10) {
			str += '0'
		}
		str += Math.abs(this.minutes)
		return str
	}
	public static fromStringHHmm(
		str: string | null | undefined
	): TimeSpan | null {
		if (!str) return null

		const isValid = /^-?\d+\:\d{1,2}$/.test(str)
		if (!isValid) return null

		const parts = str.split(':')
		const hours = parseInt(parts[0])
		const minutes = parseInt(parts[1])
		const timeSpan = new TimeSpan(0, Math.abs(hours), minutes, 0, 0)

		if (hours < 0) {
			return TimeSpan.ZERO.substract(timeSpan)
		}
		return timeSpan
	}
}
