import {
	EntityActionOptions,
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	MergeStrategy,
} from '@ngrx/data'
import { Absence } from './absence.model'
import { Injectable } from '@angular/core'
import { DateUtils } from '../../utils/date-utils'
import { Observable, shareReplay } from 'rxjs'

@Injectable()
export class AbsenceService extends EntityCollectionServiceBase<Absence> {
	private _loadedAbsences: {
		personId: number
		year: number
		month: number
	}[] = []

	constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
		super('Absence', serviceElementsFactory)
	}

	loadMonthlyAbsences(currentDate: Date, personId: number) {
		const year = currentDate.getFullYear()
		const monthIndex = currentDate.getMonth()

		if (
			this._loadedAbsences.some(
				(i) =>
					i.personId === personId && i.year === year && i.month === monthIndex
			)
		) {
			return
		}

		this._loadedAbsences.push({
			personId: personId,
			year: year,
			month: monthIndex,
		})

		const { startDate, endDate } = this.calculateDateRange(currentDate)
		this.loadAbsences(personId, startDate, endDate)
	}

	private loadAbsences(
		personId: number,
		start: Date,
		end: Date
	): Observable<Absence[]> {
		const queryParams = {
			personId: personId,
			startDate: DateUtils.formatDateWithoutTimezone(start),
			endDate: DateUtils.formatDateWithoutTimezone(end),
		}
		const request$ = this.getWithQuery(queryParams, {
			mergeStrategy: MergeStrategy.OverwriteChanges,
		}).pipe(shareReplay())

		request$.subscribe({
			error: () => (this._loadedAbsences = []),
		})

		return request$
	}

	override clearCache(): void {
		this._loadedAbsences = []
		super.clearCache()
	}

	calculateDateRange(currentDate: Date): {
		startDate: Date
		endDate: Date
	} {
		const previousMonthDate = new Date(currentDate)
		previousMonthDate.setMonth(currentDate.getMonth() - 1)
		const startOfMonth = DateUtils.getFirstDateOfMonth(previousMonthDate)

		const nextMonthDate = new Date(currentDate)
		nextMonthDate.setMonth(currentDate.getMonth() + 1)
		const endOfMonth = DateUtils.getLastDateOfMonth(nextMonthDate)

		return {
			startDate: startOfMonth,
			endDate: endOfMonth,
		}
	}
}
