import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MbscDatepickerModule } from '@mobiscroll/angular'
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core'
import { timezonePlugin } from 'src/app/shared/plugins/timezone.plugin'

@Component({
	imports: [FormlyModule, MbscDatepickerModule, ReactiveFormsModule],
	standalone: true,
	selector: 'kk-time-picker',
	template: `
		<div class="input-group">
			<button
				class="btn btn-neutral-10 border border-1 border-neutral-30"
				(click)="picker.open()"
				type="button"
			>
				<i class="fa-light fa-clock"></i>
			</button>
			<mbsc-datepicker
				class="form-control"
				[formControl]="formControl"
				[formlyAttributes]="field"
				[controls]="['time']"
				[minTime]="field.props.min"
				[maxTime]="field.props.max"
				timeFormat="HH:mm"
				[timezonePlugin]="
					field.props['timezone'] === false ? undefined : timezonePlugin
				"
				dataTimezone="utc"
				displayTimezone="local"
				returnFormat="locale"
				#picker
			></mbsc-datepicker>
		</div>
	`,
})
export class TimePickerComponent extends FieldType<FieldTypeConfig> {
	timezonePlugin = timezonePlugin
}
