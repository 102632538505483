import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
	selector: '[kkDurationPicker]',
	standalone: true,
})
export class DurationPickerDirective {
	public static readonly ZERO = '0:00'
	private navigationKeys = [
		'Backspace',
		'Delete',
		'ArrowLeft',
		'ArrowRight',
		':',
	]
	@Input()
	inputElement: HTMLInputElement

	constructor(
		el: ElementRef,
		private readonly control: NgControl
	) {
		this.inputElement = el.nativeElement
		this.inputElement.placeholder = 'hh:mm'
		this.inputElement.style.textAlign = 'right'
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		const target = e.target as HTMLInputElement
		if (this.navigationKeys.indexOf(e.key) > -1 || !target) {
			return
		}
		if (e.key === 'Tab') {
			if (this.isFocusOnHours()) {
				this.validateInput()
				this.focusOnMinutes()
				e.preventDefault()
			}
		} else if (!this.isValidKey(e.key)) {
			e.preventDefault()
		}
	}

	@HostListener('change') ngOnChanges() {
		this.validateInput()
	}

	@HostListener('click') ngOnClick() {
		this.focusOnHours()
	}

	isValidKey(key: string) {
		const isNumber = !isNaN(parseInt(key))
		return isNumber || (key === '-' && this.isFocusOnHours())
	}

	validateInput() {
		const formattedValue = DurationPickerDirective.formatInput(
			this.inputElement.value
		)

		this.control.control?.setValue(formattedValue)
	}

	public static formatInput(input: string): string {
		const parts = input.split(':')
		const hoursIsValid = /^-?\d+$/.test(parts[0])

		if (parts.length === 1 && hoursIsValid) {
			return parts[0] + ':00'
		}
		if (parts.length > 2) {
			return DurationPickerDirective.ZERO
		}
		if (!hoursIsValid) {
			parts[0] = '0'
		}

		const minutesIsValid = /^\d{1,2}$/.test(parts[1])
		const minutes = parseInt(parts[1])

		if (!minutesIsValid) {
			parts[1] = '00'
		} else if (minutes < 0) {
			parts[1] = '00'
		} else if (minutes > 59) {
			parts[1] = '59'
		} else if (parts[1].length === 1) {
			parts[1] = '0' + parts[1]
		}
		return parts.join(':')
	}

	isFocusOnHours() {
		const cursorPosition = this.inputElement.selectionStart ?? 0
		let indexOfSeparator = this.inputElement.value.indexOf(':')
		// Hours are first so if no separator then return true
		if (indexOfSeparator === -1) return true

		return cursorPosition <= indexOfSeparator
	}

	focusOnHours() {
		let indexOfSeparator = this.inputElement.value.indexOf(':')
		this.inputElement.setSelectionRange(0, indexOfSeparator)
	}
	focusOnMinutes() {
		let indexOfSeparator = this.inputElement.value.indexOf(':')
		this.inputElement.setSelectionRange(
			indexOfSeparator + 1,
			this.inputElement.value.length
		)
	}
}
