import {
	EntityCollectionServiceBase,
	EntityCollectionServiceElementsFactory,
	MergeStrategy,
} from '@ngrx/data'
import { Injectable } from '@angular/core'
import { Observable, shareReplay } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { CreateBalanceLock, BalanceLock } from './balance-lock.model'

@Injectable()
export class BalanceLockService extends EntityCollectionServiceBase<BalanceLock> {
	constructor(
		serviceElementsFactory: EntityCollectionServiceElementsFactory,
		private httpClient: HttpClient
	) {
		super('BalanceLock', serviceElementsFactory)
	}

	public create(createModel: CreateBalanceLock): Observable<BalanceLock> {
		const request$ = this.httpClient
			.post<BalanceLock>('balance-lock', createModel)
			.pipe(shareReplay())

		request$.subscribe((entity) => {
			if (entity) {
				this.addOneToCache(entity, {
					mergeStrategy: MergeStrategy.OverwriteChanges,
				})
			}
		})

		return request$
	}
	public getWorkTimeFlexBalance(
		personId: number,
		date: string
	): Observable<number | null> {
		const params = new HttpParams().set('date', date).set('personId', personId)
		return this.httpClient
			.get<number>('time-bank-balance/get-work-time-flex-balance', {
				params: params,
			})
			.pipe(shareReplay())
	}
}
